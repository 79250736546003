import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon, Image, TextCutter, YoutubeCmsServerError } from '@src/components/atoms';
import Popover, { MenuItem } from '@src/components/atoms/Popover';
import { ListLoading, PagerV2 } from '@src/components/molecules';
import { getPageInfo } from '@src/components/molecules/Pager/helpers';
import { Toolbar, YearMonthPicker } from '@src/components/shared';
import {
  useYoutubeCmsConfirmedVideosForInfluencerQuery,
  useYoutubeCmsVideosForInfluencerQuery,
} from '@src/graphql/hooks';
import { CURRENCY_SYMBOLS, LIMIT_10 } from '@src/libs/constant';
import { ErrorTypes } from '@src/libs/error';
import { useYoutubeCmsRevenue } from '@src/libs/graphql';
import { getFirstDayOfTheMonth, sliceItemsOnPage } from '@src/libs/functions';
import { formatNumberWithCommas } from '@src/libs/format';
import { useFilter, usePageLayout, useUrl } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';

enum Sort {
  LOWEST_REVENUE = 'LOWEST_REVENUE',
  HIGHEST_REVENUE = 'HIGHEST_REVENUE',
}

const YoutubeCmsDashboard = () => {
  const [sortBy, setSortBy] = useState<Sort>(Sort.HIGHEST_REVENUE);
  const { searchParams } = useUrl();
  const page = Number(searchParams.get('p')) || 1;
  const { filter, setFilter } = useFilter({
    month: searchParams.get('month') || '',
    p: page,
  });
  const { isMobileView } = usePageLayout();
  const { t } = useTranslation();
  const { isConfirmedRevenue, loadingRevenue } = useYoutubeCmsRevenue(
    filter.month ? { month: filter.month } : undefined
  );
  const sorting = [
    { title: 'Option.Highest Revenue', value: Sort.HIGHEST_REVENUE },
    { title: 'Option.Lowest Revenue', value: Sort.LOWEST_REVENUE },
  ];
  const sortByTitle = sorting.find(s => s.value === sortBy)?.title || '';

  const {
    data: dataConfirmedVideos,
    error: errorConfirmedVideos,
    loading: loadingConfirmedVideos,
  } = useYoutubeCmsConfirmedVideosForInfluencerQuery({
    skip: !filter.month || loadingRevenue || !isConfirmedRevenue,
    variables: {
      month: filter.month,
    },
  });
  const {
    data: dataVideos,
    error: errorVideos,
    loading: loadingVideos,
  } = useYoutubeCmsVideosForInfluencerQuery({
    skip: !filter.month || loadingRevenue || isConfirmedRevenue,
    variables: {
      month: filter.month,
    },
  });
  const isServerError = [errorConfirmedVideos?.message, errorVideos?.message].includes(ErrorTypes.DATA_TIMEOUT);
  const videoList = [
    ...(dataConfirmedVideos?.youtubeCmsConfirmedVideosForInfluencer.map(v => ({
      ...v,
      title: v.name,
    })) ||
      dataVideos?.youtubeCmsVideosForInfluencer ||
      []),
  ]
    // id with 'other' only used on revenue page to show 'total revenue'
    .filter(v => v.id !== 'other')
    .sort((a, b) => (sortBy === Sort.HIGHEST_REVENUE ? b.revenue - a.revenue : a.revenue - b.revenue));
  const pageInfo = getPageInfo(page, videoList.length, LIMIT_10);

  useEffect(() => {
    if (!filter.month) {
      setFilter({ ...filter, month: getFirstDayOfTheMonth(), p: page });
    }
  }, [filter.month]);

  return (
    <div css={{ display: 'grid', gap: THEME.box.gaps.l }}>
      <Toolbar hasNotification title="Dashboard" />
      <div css={styles.contentContainer}>
        <div css={styles.pickerContainer}>
          <YearMonthPicker
            disabledDates={{ after: new Date() }}
            value={filter.month || ''}
            onChange={month => setFilter({ ...filter, month, p: 1 })}
          />
        </div>

        {isServerError ? (
          <div css={styles.serverErrorContainer}>
            <YoutubeCmsServerError pageTitle="Dashboard" />
          </div>
        ) : (
          <>
            <div css={styles.filterContainer}>
              <label className="title">{t('Video')}</label>
              <Popover
                css={styles.filterPopover}
                renderTrigger={
                  <div className="filter-button">
                    <Icomoon icon="air-filter" size={22} />
                    <label>{t(sortByTitle)}</label>
                  </div>
                }
              >
                {sorting.map(({ title, value }) => (
                  <MenuItem key={value} onClick={() => setSortBy(value)}>
                    {t(title)}
                  </MenuItem>
                ))}
              </Popover>
            </div>
            {loadingRevenue || loadingConfirmedVideos || loadingVideos ? (
              <ListLoading hideBorder />
            ) : (
              (isMobileView ? videoList : sliceItemsOnPage(videoList, page)).map(
                ({ currency, id, revenue, thumbnail, title }, index) => {
                  const currencySymbol = CURRENCY_SYMBOLS[currency] || '';

                  return (
                    <Link
                      css={styles.listItem}
                      key={`${id}_${index}`}
                      to={generatePath(
                        ROUTES.YOUTUBE_CMS_DASHBOARD_VIDEOID,
                        { videoId: id },
                        {
                          currency,
                          month: filter.month,
                          ...(isConfirmedRevenue ? { confirmedRevenue: revenue } : { estimatedRevenue: revenue }),
                        }
                      )}
                    >
                      <Image css={{ borderRadius: 11 }} height="56px" hideNoImageText src={thumbnail} width="56px" />
                      <div className="title-container">
                        <TextCutter className="title" lines={1} text={title || id} />
                        <label className="revenue">{`${t(
                          isConfirmedRevenue ? 'Confirmed Revenue' : 'Est Revenue'
                        )}: ${currencySymbol}${formatNumberWithCommas(revenue, undefined, currency)}`}</label>
                      </div>
                      <Icomoon className="arrow" icon="arrow-down" size={10} />
                    </Link>
                  );
                }
              )
            )}
          </>
        )}
      </div>

      {!isMobileView && !isServerError && (
        <PagerV2
          css={{ justifySelf: 'center', marginBottom: 24 }}
          currentPage={page}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
          totalCount={pageInfo.totalCount}
          totalPages={pageInfo.totalPages}
        />
      )}
    </div>
  );
};

const styles = {
  contentContainer: css({
    background: THEME.colors.gray.dee5ec,
    display: 'grid',
    gap: 1,
    justifySelf: 'center',
    maxWidth: 614,
    overflow: 'hidden',
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.l,
    },
  }),
  filterContainer: css({
    alignItems: 'center',
    background: THEME.colors.gray.f6f8fa,
    display: 'flex',
    padding: '12px 16px',

    '& > .title': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      width: 'fill-available',
    },

    '& > .filter-button': {
      background: THEME.colors.white,
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.m,
      display: 'flex',
      gap: THEME.box.gaps.xs,
      padding: 12,

      '& > label': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.subHeading,
        width: 'max-content',
      },
    },
  }),
  filterPopover: css({
    background: THEME.colors.gray.dee5ec,
    border: THEME.box.borders.gray.main,
    borderRadius: THEME.box.borderRadius.m,
    display: 'grid',
    gap: 1,
    overflow: 'hidden',

    '& > div': {
      background: THEME.colors.white,
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      padding: 12,
    },
  }),
  listItem: css({
    alignItems: 'center',
    background: THEME.colors.white,
    cursor: 'pointer',
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: '8px 16px',

    '& > .title-container': {
      display: 'grid',
      gap: THEME.box.gaps.xs,
      width: 'fill-available',

      '& > .title': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
      },

      '& > .revenue': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.hint,
      },
    },

    '& > .arrow': {
      transform: 'rotate(270deg)',

      "[dir='rtl'] &": {
        transform: 'rotate(90deg)',
      },
    },
  }),
  pickerContainer: css({
    background: THEME.colors.white,
    padding: '12px 16px',
  }),
  serverErrorContainer: css({
    alignItems: 'center',
    background: THEME.colors.gray.f6f8fa,
    display: 'grid',
    height: 'calc(100vh - 168px)',
    justifyContent: 'center',
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      height: 400,
    },
  }),
};

export default YoutubeCmsDashboard;
