import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';
import Pager from './Pager';

const PagerV2 = styled(Pager)({
  '& > div:nth-of-type(1)': {
    display: 'none',
  },

  '& p': {
    borderRadius: THEME.box.borderRadius.xs,
    width: 88,
  },

  '& li': {
    borderRadius: THEME.box.borderRadius.xs,
  },
});

export default PagerV2;
