import { ApolloError } from '@apollo/client';
import React, { useEffect } from 'react';
import { eventTypes, sendAmplitudeEvent } from '@src/amplitude';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { InitialLoading } from '@src/components/atoms';
import { useQueryHelper } from '@src/libs/hooks';
import { getCallbackUrl, getConnectSocialAuthState, removeToken, setToken } from '@src/libs/auth';
import { useAuthSetup } from '@src/components/organisms/AuthSetUp/useAuthSetup';
import { useAnyXSocialAuthSignInMutation } from '@src/graphql/hooks';
import { getMessagesFromFetchResult, ErrorTypes, UNEXPECTED_ERROR } from '@src/libs/error';
import { switchText } from '@src/libs/socialMedia';
import { sendGAEvent, GAActions, GACategories } from '@src/libs/withTracker';

import { redirectState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import { AnyXAuthSocialAccountType, SocialAccountType } from '@src/__generated__/globalTypes';
import { useAuthProviderResponse } from '../hooks';

const SignInRedirect = () => {
  const { recoilState, resetRecoilState } = useRecoil(redirectState);
  const [anyXSocialAuthSignIn] = useAnyXSocialAuthSignInMutation();
  const { setUp } = useAuthSetup();
  const {
    connectState: { socialMedia },
    resetConnectState,
  } = getConnectSocialAuthState();
  const { t, enqueueSnackbar, navigate } = useQueryHelper();

  const { provider, response } = useAuthProviderResponse(ROUTES.SIGN_IN);

  const signInRedirectCall = async () => {
    if (!provider) {
      enqueueSnackbar(t(UNEXPECTED_ERROR), { variant: 'error' });
      navigate(ROUTES.ROOT);

      return;
    }

    // Redirect with state for connecting FB pages and an IG account.
    let socialMediaProvider = provider;
    if (socialMedia === SocialAccountType.INSTAGRAM) {
      socialMediaProvider = SocialAccountType.INSTAGRAM;
    }

    const variables = {
      input: {
        provider: socialMediaProvider,
        response,
        callbackUrl: getCallbackUrl(socialMediaProvider, FE_REDIRECT_MAPPING.SIGNIN),
      },
    };

    const { token, refreshToken, errors } = await anyXSocialAuthSignIn({ variables })
      .then(result => {
        if (result && result.data && result.data.anyXSocialAuthSignIn) {
          const payload = result.data.anyXSocialAuthSignIn;

          return {
            token: payload.token,
            refreshToken: null,
            errors: [],
          };
        } else {
          return {
            token: null,
            refreshToken: null,
            errors: getMessagesFromFetchResult(result),
          };
        }
      })
      .catch((e: ApolloError) => ({
        token: null,
        refreshToken: null,
        // @ts-ignore, see issue -> https://github.com/apollographql/@apollo/client/issues/1496
        errors: [e?.graphQLErrors?.[0]?.message],
      }));

    // SocialAuthentication Failed
    if (!token || errors.length > 0) {
      errors.forEach(error => {
        console.error(error);
        if (socialMediaProvider === AnyXAuthSocialAccountType.TIKTOK && error === ErrorTypes.VIDEO_PERMISSION_MISSING) {
          navigate(ROUTES.SIGN_IN_TIKTOK_GRANT_PERMISSION);
        } else if (
          socialMediaProvider === AnyXAuthSocialAccountType.FACEBOOK &&
          error === ErrorTypes.FB_REQUIRED_PERMISSION_MISSING
        ) {
          navigate(ROUTES.SIGN_IN_FACEBOOK_GRANT_PERMISSION);
        } else if (socialMediaProvider === AnyXAuthSocialAccountType.INSTAGRAM) {
          navigate(ROUTES.SIGN_IN_IG_UNABLE_CONNECT);
        } else {
          enqueueSnackbar(t(error), { variant: 'error' });
          navigate(ROUTES.SIGN_IN);
        }

        return;
      });

      return;
    }

    // Sign In with token
    setToken(token, refreshToken);

    try {
      await setUp(token);
      const accessUrlBeforeSignIn = recoilState.accessUrlBeforeSignIn;
      if (window.opener) {
        window.opener.postMessage({ redirectPath: accessUrlBeforeSignIn || ROUTES.ROOT }, '*');
        window.close();

        return;
      }
      sendAmplitudeEvent(eventTypes.signIn, { signInMethod: switchText(socialMediaProvider) });
      sendGAEvent({
        action: GAActions.ACTIVE_USER,
        category: GACategories.AUTH,
        label: switchText(socialMediaProvider),
      });
      navigate(accessUrlBeforeSignIn ? accessUrlBeforeSignIn : ROUTES.ROOT);
      resetRecoilState();
    } catch (e) {
      removeToken();
      console.error(e);
      enqueueSnackbar(t(e.message), { variant: 'error' });
      if (window.opener) {
        window.opener.postMessage({ redirectPath: ROUTES.ROOT, errorMsg: e.message }, '*');
        window.close();

        return;
      }
      navigate(ROUTES.ROOT);
    }

    return;
  };

  useEffect(() => {
    signInRedirectCall();

    return () => {
      resetConnectState();
    };
  }, []);

  return <InitialLoading />;
};

export default SignInRedirect;
