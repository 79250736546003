import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SeriesOptionsType } from 'highcharts';
import HighChart, { Highcharts } from '@src/components/atoms/HighChart';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { formatPercent } from '@src/libs/format';
import { useDirLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { TooltipTemplateSmall } from '../TooltipTemplates';
import { FollowerAgeRate } from './types';

const AgeBreakdown = (props: FollowerAgeRate) => {
  const { ageGroup, maleRate, femaleRate, otherRate, totalRate, className } = props;
  const { t } = useTranslation();
  const { dir, isRtl } = useDirLayout();
  const isOtherRateAvailable = !!(otherRate && otherRate.find(rate => rate > 0));

  const options: Highcharts.Options = {
    chart: {
      height: 273,
      marginBottom: 70,
    },
    title: {
      text: '',
    },
    legend: {
      enabled: true,
      symbolRadius: 2,
      itemStyle: {
        fontSize: '13px',
        lineHeight: '1.38',
      },
      rtl: isRtl,
    },
    xAxis: {
      categories: ageGroup?.map(item => `${item.start}-${item.end}`) || [],
      plotLines: [
        {
          color: '#dee5ec',
          width: 1,
          value: 6.5,
        },
      ],
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
    // @ts-ignore
    yAxis: {
      title: '',
      labels: {
        formatter(): string {
          return formatPercent(+this.value, true, 0);
        },
      },
    },
    series: totalRate
      ? [
          {
            name: t<string>('Total'),
            data: totalRate || [],
            color: '#9EA0E0',
            type: 'bar',
          },
        ]
      : [
          {
            type: 'bar',
            name: t<string>('Male'),
            color: '#707fc0',
            data: maleRate || [],
          },
          {
            type: 'bar',
            name: t<string>('Female'),
            color: '#ff8097',
            data: femaleRate || [],
          },
          ...(isOtherRateAvailable
            ? [
                {
                  type: 'bar',
                  name: t<string>('Other'),
                  color: '#ffca98',
                  data: otherRate || [],
                } as SeriesOptionsType,
              ]
            : []),
        ],
    tooltip: {
      padding: 0,
      formatter(): string {
        return TooltipTemplateSmall({
          seriaName: this.point.category,
          point: this.point,
          name: this.series.name,
          dir,
        });
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: THEME.colors.black.main,
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      headerFormat: '',
    },
  };

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Title.Age Breakdown')}</SectionHeader>}
      dataNotAvailable={!femaleRate?.length && !maleRate?.length && !otherRate?.length && !totalRate?.length}
      isAvailableRate={Number(maleRate) > 0 || Number(femaleRate) > 0}
      className={className}
    >
      <HighChart options={options} />
    </DataOpacityWrapper>
  );
};

export default AgeBreakdown;
