import { format } from 'date-fns';
import range from 'lodash/range';
import React, { useState } from 'react';
import { css } from '@emotion/react';
import { emptyListImg } from '@src/assets/img/youtubeCMSInvoice';
import { Icomoon } from '@src/components/atoms';
import { ListLoading, PagerV2, ThemeButton } from '@src/components/molecules';
import { getPageInfo } from '@src/components/molecules/Pager/helpers';
import { SelectV2, Toolbar } from '@src/components/shared';
import { useCmsInvoiceDownloadUrlLazyQuery, useYoutubeCmsInvoiceListForInfluencerQuery } from '@src/graphql/hooks';
import { JAPANESE_LANG, LIMIT_10 } from '@src/libs/constant';
import { getDateLocal } from '@src/libs/date';
import { sliceItemsOnPage } from '@src/libs/functions';
import { formatDate } from '@src/libs/format';
import { useFilter, usePageLayout, useQueryHelper, useUrl } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { downloadFile } from '@src/libs/requests/downloadFile';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

const YoutubeCmsInvoice = () => {
  const [isDownloadingId, setIsDownloadingId] = useState<number | null>(null);
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, i18n, t } = useQueryHelper();
  const { searchParams } = useUrl();
  const page = Number(searchParams.get('p')) || 1;
  const { filter, setFilter } = useFilter({
    year: searchParams.get('year') || '',
    p: page,
  });

  const currentYear = new Date().getFullYear();
  const isJPLanguage = i18n.language === JAPANESE_LANG;
  const years = range(currentYear - 70, currentYear + 1).map(year => {
    const value = year.toString();

    return {
      label: value,
      value,
    };
  });

  const [invoiceDownloadUrl] = useCmsInvoiceDownloadUrlLazyQuery();
  const { data: dataInvoiceList, loading } = useYoutubeCmsInvoiceListForInfluencerQuery({
    skip: !filter.year,
    variables: {
      year: Number(filter.year),
    },
  });
  const invoiceList = dataInvoiceList?.youtubeCmsInvoiceListForInfluencer || [];
  const pageInfo = getPageInfo(page, invoiceList.length, LIMIT_10);

  const onClickDownloadFile = async (id: number) => {
    setIsDownloadingId(id);
    try {
      const { data } = await invoiceDownloadUrl({ variables: { id } });
      if (data?.cmsInvoiceDownloadUrl?.url) {
        await downloadFile({
          url: new URL(data.cmsInvoiceDownloadUrl.url),
          filenamePrefix: 'invoice',
          hasAuthHeader: false,
        });
      }
    } catch (err) {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    }
    setIsDownloadingId(null);
  };

  return (
    <div css={{ display: 'grid', gap: THEME.box.gaps.l }}>
      <Toolbar hasNotification title="Invoice" />
      <div css={styles.contentContainer}>
        <div css={styles.dropdownContainer}>
          <SelectV2
            disableClear
            options={years}
            placeholder="Select Year"
            value={filter.year || ''}
            onChange={val => setFilter({ ...filter, year: val })}
          />
        </div>
        {loading ? (
          <ListLoading css={{ background: THEME.colors.gray.f6f8fa }} />
        ) : !!invoiceList.length ? (
          (isMobileView ? invoiceList : sliceItemsOnPage(invoiceList, page)).map(({ id, month, updatedDate }) => (
            <div css={styles.listItem} key={id}>
              <div>
                <label className="invoice-date">
                  {t('Invoice for date', {
                    date: format(new Date(month), isJPLanguage ? 'MMMM yo' : 'MMMM yyyy', {
                      locale: getDateLocal(i18n.language as AppLanguage),
                    }),
                  })}
                </label>
                <label className="updated-date">
                  {t('Updated date', {
                    date: formatDate(updatedDate, isJPLanguage ? 'PPP' : 'yyyy/MM/dd'),
                  })}
                </label>
              </div>
              <ThemeButton
                className="download-btn"
                loading={isDownloadingId === id}
                prefixIcon={<Icomoon icon="import-straight-line" />}
                text="Download file"
                onClick={() => onClickDownloadFile(id)}
              />
            </div>
          ))
        ) : (
          <div css={styles.emptyList}>
            <img className="image" src={emptyListImg} />
            <label className="description">{t('Annotation.For now, there’s no Invoice to show')}</label>
          </div>
        )}
      </div>

      {!isMobileView && (
        <PagerV2
          css={{ justifySelf: 'center', marginBottom: 24 }}
          currentPage={page}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
          totalCount={pageInfo.totalCount}
          totalPages={pageInfo.totalPages}
        />
      )}
    </div>
  );
};

const styles = {
  contentContainer: css({
    background: THEME.colors.gray.dee5ec,
    display: 'grid',
    gap: 1,
    justifySelf: 'center',
    maxWidth: 614,
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: THEME.box.borderRadius.l,
      boxShadow: THEME.box.shadows.outer,
      overflow: 'hidden',
    },
  }),
  dropdownContainer: css({
    background: THEME.colors.white,
    padding: '12px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: 24,
    },
  }),
  emptyList: css({
    alignContent: 'center',
    background: THEME.colors.gray.f6f8fa,
    display: 'grid',
    gap: THEME.box.gaps.l,
    height: 400,
    justifyItems: 'center',
    width: '100%',

    '& > .image': {
      height: 160,
      width: 160,
    },

    '& > .description': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.subHeading,

      [`@media (max-width: ${ViewportType.TABLET}px)`]: {
        width: 146,
      },
    },
  }),
  listItem: css({
    background: THEME.colors.white,
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: 16,

    '& > div': {
      display: 'grid',
      gap: THEME.box.gaps.xs,
      width: 'fill-available',

      '& > .invoice-date': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
      },

      '& > .updated-date': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.hint,
      },
    },

    '& > .download-btn': {
      borderRadius: THEME.box.borderRadius.m,
      width: 'fit-content',

      '& span': {
        width: 'max-content',
      },
    },
  }),
};

export default YoutubeCmsInvoice;
