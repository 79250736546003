import { authState, useRecoil, AuthStateProps } from '@src/recoilAtoms';

const useAuthData = () => {
  const { recoilState, setRecoilState } = useRecoil(authState);

  const setAuthData = ({
    countryId = null,
    creatorType = null,
    isAvailableCountry = false,
    isYoutubeCmsRevenue = false,
    role = null,
    userId = null,
  }: Partial<AuthStateProps>) => {
    setRecoilState({
      countryId,
      creatorType,
      isAvailableCountry,
      isYoutubeCmsRevenue,
      role,
      userId,
    });
  };

  return {
    ...recoilState,
    setAuthData,
  };
};

export default useAuthData;
