import { getFirstDayOfTheMonth } from '@src/libs/functions';
import {
  useYoutubeCmsConfirmedRevenueForInfluencerQuery,
  useYoutubeCmsRevenueForInfluencerQuery,
} from '@src/graphql/hooks';

interface UseYoutubeCmsRevenueProps {
  month?: string;
  skip?: boolean;
}

const useYoutubeCmsRevenue = (props?: UseYoutubeCmsRevenueProps) => {
  const { month, skip } = { ...props };
  const firstDayOfTheMonth = month || getFirstDayOfTheMonth();

  const { data: dataConfirmedRevenue, loading: loadingConfirmedRevenue } =
    useYoutubeCmsConfirmedRevenueForInfluencerQuery({
      skip,
      variables: {
        month: firstDayOfTheMonth,
      },
    });
  const { data: dataRevenue, loading: loadingRevenue } = useYoutubeCmsRevenueForInfluencerQuery({
    skip,
    variables: {
      month: firstDayOfTheMonth,
    },
  });
  const isConfirmedRevenue = !!dataConfirmedRevenue?.youtubeCmsConfirmedRevenueForInfluencer?.revenue?.total;

  return {
    isConfirmedRevenue,
    loadingRevenue: loadingConfirmedRevenue || loadingRevenue,
    youtubeCmsRevenue: isConfirmedRevenue
      ? dataConfirmedRevenue?.youtubeCmsConfirmedRevenueForInfluencer?.revenue
      : dataRevenue?.youtubeCmsRevenueForInfluencer?.revenue,
  };
};

export default useYoutubeCmsRevenue;
