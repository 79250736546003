import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import { YoutubeCmsVideoAudienceQuery, YoutubeCmsVideoAudienceQueryVariables } from '@src/__generated__/globalTypes';
import QUERY from '../queries/YoutubeCmsVideoAudience.graphql';

export const useYoutubeCmsVideoAudienceQuery = (
  options?: QueryHookOptions<YoutubeCmsVideoAudienceQuery, YoutubeCmsVideoAudienceQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<YoutubeCmsVideoAudienceQuery, YoutubeCmsVideoAudienceQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useYoutubeCmsVideoAudienceLazyQuery = (
  options?: LazyQueryHookOptions<YoutubeCmsVideoAudienceQuery, YoutubeCmsVideoAudienceQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<YoutubeCmsVideoAudienceQuery, YoutubeCmsVideoAudienceQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
