import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, ErrorMessage, Icomoon, TextCutter } from '@src/components/atoms';
import { Dialog, ThemeButton } from '@src/components/molecules';
import { TextFormV2 } from '@src/components/shared';
import {
  useInfluencerUpdateDetailV2Query,
  useMarketplaceJoinableAccountsQuery,
  useJoinAutoManagedCampaignMutation,
  useJoinMarketplaceV2Mutation,
  useJoinTiktokSpecialCampaignMutation,
  useTiktokSpecialJoinableAccountsQuery,
  useUpdateInfluencerPhoneNumberMutation,
} from '@src/graphql/hooks';
import { FE_REDIRECT_MAPPING } from '@src/libs/constant';
import { localizedDateFormatter } from '@src/libs/date';
import { formatNumberWithCommas } from '@src/libs/format';
import { useAuthData, useConnectSocialAuth, useDirLayout, usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { AppLanguage } from '@src/libs/i18n/languageDetector/utils';
import { mainBlack } from '@src/libs/palette';
import { switchSocialBackgroundColor, switchImage, switchText } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { toolbarBackState, useRecoil } from '@src/recoilAtoms';
import { generatePath, ROUTES } from '@src/shared/routes';
import { CampaignPromotionMethod, CampaignSocialMediaType, SocialAccountType } from '@src/__generated__/globalTypes';
import { DIALOG_REQUIRED_ACTIONS } from './helpers';

interface JoinButtonProps {
  campaign: {
    currency?: string | null;
    endDate?: any;
    id: number | string;
    isPostTracking?: boolean | null;
    isSelectionCampaign?: boolean | null;
    isWaiting?: boolean;
    maximumRevenuePerInfluencer?: number | null;
    minimumPaymentAmount: number;
    requireDraftPost?: boolean;
    socialMedias?: CampaignSocialMediaType[];
    startDate?: any;
  };
  disabled?: boolean;
  isAutoManagedCampaign?: boolean;
  isTikTokSpecialCampaign?: boolean;
  searchId: number;
  setIsJoinableMethods?: (isJoinableMethods: boolean) => void;
}

interface PromotionMethods {
  activeToken?: boolean;
  avatar?: string;
  disabled?: boolean;
  followersCount?: number;
  id: number;
  name?: string;
  type: SocialAccountType;
}

enum DialogStatus {
  CLOSE = 'CLOSE',
  CONNECT_SOCIAL_ACCS = 'CONNECT_SOCIAL_ACCS',
  CHOOSE_PROMOTION_METHODS = 'CHOOSE_PROMOTION_METHODS',
  CONFIRM_PHONE_NUMBER = 'CONFIRM_PHONE_NUMBER',
  REGISTER_PHONE_NUMBER = 'REGISTER_PHONE_NUMBER',
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
}

const JoinButton = ({
  campaign,
  disabled,
  isAutoManagedCampaign,
  isTikTokSpecialCampaign,
  searchId,
  setIsJoinableMethods,
}: JoinButtonProps) => {
  const {
    currency,
    endDate,
    id,
    isPostTracking,
    isSelectionCampaign,
    isWaiting,
    maximumRevenuePerInfluencer,
    minimumPaymentAmount,
    requireDraftPost,
    socialMedias = [CampaignSocialMediaType.UNSELECT],
    startDate,
  } = campaign;
  const [dialog, setDialog] = useState<DialogStatus>(DialogStatus.CLOSE);
  const [isJoining, setIsJoining] = useState<boolean>(false);
  const [selectedMethodIds, setSelectedMethodIds] = useState<number[]>([]);
  const [selectedMethods, setSelectedMethods] = useState<(CampaignPromotionMethod | SocialAccountType)[]>([]);
  const dialogContent = useRef<HTMLDivElement | null>(null);
  const { userId } = useAuthData();
  const { connectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.CONNECT);
  const { connectSocialAuth: tiktokReconnectSocialAuth } = useConnectSocialAuth(FE_REDIRECT_MAPPING.TIKTOK_RECONNECT);
  const { isRtl } = useDirLayout();
  const { isMobileView } = usePageLayout();
  const { recoilState, setRecoilState } = useRecoil(toolbarBackState);
  const { enqueueSnackbar, navigate, i18n, t } = useQueryHelper();

  const [joinAutoManagedCampaign] = useJoinAutoManagedCampaignMutation();
  const [joinMarketplaceV2] = useJoinMarketplaceV2Mutation();
  const [joinTikTokSpecialCampaign] = useJoinTiktokSpecialCampaignMutation();
  const campaignId = Number(id);
  const isCampaignPromotionMethods = !!socialMedias?.find(
    socialMedia => socialMedia !== CampaignSocialMediaType.UNSELECT
  );

  const validationSchema = yup.object().shape({
    phoneNumber: yup.string().matches(/^\+?\d{0,3}\d{4,15}$/, 'invalidPhoneNumberMessage'),
  });
  const {
    formState: { errors },
    clearErrors,
    register,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<{ phoneNumber: string }>({
    defaultValues: { phoneNumber: '' },
    resolver: yupResolver(validationSchema),
  });
  const [phoneNumber] = watch(['phoneNumber']);
  const { data } = useInfluencerUpdateDetailV2Query({
    skip: !isTikTokSpecialCampaign,
    variables: {
      pk: Number(userId),
    },
    onCompleted: results => {
      setValue('phoneNumber', results.influencerUpdateDetailV2?.phoneNumber || '');
    },
  });
  const influencerPhoneNumber = data?.influencerUpdateDetailV2?.phoneNumber || '';

  const { data: dataMarketplaceJoinableAccounts } = useMarketplaceJoinableAccountsQuery({
    skip: !isPostTracking || isTikTokSpecialCampaign,
    variables: {
      campaignId,
    },
  });
  const { data: dataTikTokSpecialJoinableAccounts } = useTiktokSpecialJoinableAccountsQuery({
    skip: !isTikTokSpecialCampaign,
    variables: { campaignId },
  });
  const accounts = isTikTokSpecialCampaign
    ? {
        facebookAccount: null,
        facebookPages: [],
        instagramAccounts: [],
        tiktokAccounts: dataTikTokSpecialJoinableAccounts?.tiktokSpecialJoinableAccounts?.tiktokAccounts || [],
        twitterAccounts: [],
        youtubeAccounts: [],
      }
    : dataMarketplaceJoinableAccounts?.marketplaceJoinableAccounts;
  const [updateInfluencerPhoneNumber] = useUpdateInfluencerPhoneNumberMutation();

  const matchSocialMediaTypes = (types: CampaignSocialMediaType[] = []) =>
    types.concat(CampaignSocialMediaType.UNSELECT).some(type => socialMedias.includes(type));

  const joinableAccounts = (() => [
    ...(matchSocialMediaTypes([CampaignSocialMediaType.FACEBOOK]) && accounts?.facebookAccount
      ? [{ ...accounts.facebookAccount, type: SocialAccountType.FACEBOOK }]
      : []),
    ...((matchSocialMediaTypes([CampaignSocialMediaType.FACEBOOK]) &&
      accounts?.facebookPages.map(page => ({
        ...page,
        type: SocialAccountType.FACEBOOK_PAGE,
      }))) ||
      []),
    ...((matchSocialMediaTypes([CampaignSocialMediaType.INSTAGRAM, CampaignSocialMediaType.INSTAGRAM_STORY]) &&
      accounts?.instagramAccounts.map(account => ({
        ...account,
        type: SocialAccountType.INSTAGRAM,
      }))) ||
      []),
    ...((matchSocialMediaTypes([CampaignSocialMediaType.TIKTOK]) &&
      accounts?.tiktokAccounts.map(account => ({
        ...account,
        type: SocialAccountType.TIKTOK,
      }))) ||
      []),
    ...((matchSocialMediaTypes([CampaignSocialMediaType.TWITTER]) &&
      accounts?.twitterAccounts.map(account => ({
        ...account,
        type: SocialAccountType.TWITTER,
      }))) ||
      []),
    ...((matchSocialMediaTypes([CampaignSocialMediaType.YOUTUBE]) &&
      accounts?.youtubeAccounts.map(account => ({
        ...account,
        type: SocialAccountType.YOUTUBE,
      }))) ||
      []),
  ])() as PromotionMethods[];
  const promotionMethods = [
    ...(matchSocialMediaTypes([CampaignSocialMediaType.INSTAGRAM])
      ? [{ icon: 'instagram', type: CampaignPromotionMethod.INSTAGRAM }]
      : []),
    ...(matchSocialMediaTypes([CampaignSocialMediaType.INSTAGRAM_STORY])
      ? [{ icon: 'instagram', type: CampaignPromotionMethod.INSTAGRAM_STORY }]
      : []),
    ...(matchSocialMediaTypes([CampaignSocialMediaType.FACEBOOK])
      ? [{ icon: 'facebook-round', type: CampaignPromotionMethod.FACEBOOK }]
      : []),
    ...(matchSocialMediaTypes([CampaignSocialMediaType.YOUTUBE])
      ? [{ icon: 'youtube', type: CampaignPromotionMethod.YOUTUBE }]
      : []),
    ...(matchSocialMediaTypes([CampaignSocialMediaType.TWITTER])
      ? [{ icon: 'x-twitter', type: CampaignPromotionMethod.TWITTER }]
      : []),
    ...(matchSocialMediaTypes([CampaignSocialMediaType.TIKTOK])
      ? [{ icon: 'tiktok-white', type: CampaignPromotionMethod.TIKTOK }]
      : []),
    ...(matchSocialMediaTypes([CampaignSocialMediaType.OTHER])
      ? [
          { icon: 'website', title: 'Website', type: CampaignPromotionMethod.WEBSITE },
          { icon: 'mail-outlined', title: 'Email / Newsletter', type: CampaignPromotionMethod.EMAIL_NEWSLETTER },
          { icon: 'smartphone', title: 'Mobile App', type: CampaignPromotionMethod.MOBILE_APP },
          { icon: 'podcast', title: 'Podcast', type: CampaignPromotionMethod.PODCAST },
          { icon: 'ellipsis', title: 'Offline / Others', type: CampaignPromotionMethod.OFFLINE },
        ]
      : []),
  ];
  const isJoinableAccounts = !isPostTracking || !!joinableAccounts.length;

  useEffect(() => {
    setIsJoinableMethods?.(isJoinableAccounts);
  }, [isJoinableAccounts]);

  const onClickConnect = (type: SocialAccountType) => {
    let sm = type;
    if (type === SocialAccountType.FACEBOOK_PAGE) {
      sm = SocialAccountType.FACEBOOK;
    }

    connectSocialAuth(sm, { marketplaceId: searchId });
  };

  const onClickNext = async () => {
    if (dialog === DialogStatus.TERMS_CONDITIONS) {
      setDialog(DialogStatus.CHOOSE_PROMOTION_METHODS);
    } else if (dialog === DialogStatus.CHOOSE_PROMOTION_METHODS && isTikTokSpecialCampaign) {
      setDialog(DialogStatus.REGISTER_PHONE_NUMBER);
    } else if (dialog === DialogStatus.REGISTER_PHONE_NUMBER && phoneNumber !== influencerPhoneNumber) {
      trigger().then(isValid => {
        if (isValid) {
          setDialog(DialogStatus.CONFIRM_PHONE_NUMBER);
        } else {
          setError('phoneNumber', { type: 'custom', message: 'invalidPhoneNumberMessage' });
        }
      });
    } else {
      setIsJoining(true);
      try {
        if (dialog === DialogStatus.CONFIRM_PHONE_NUMBER) {
          await updateInfluencerPhoneNumber({ variables: { input: { phoneNumber } } });
        }

        if (isAutoManagedCampaign) {
          const { data: dataAutoManaged } = await joinAutoManagedCampaign({
            variables: {
              input: {
                campaignId: String(campaignId),
                methods: selectedMethods as CampaignPromotionMethod[],
              },
            },
          });
          // delay 0.1s for dialog remove lock scroll
          setTimeout(() => {
            navigate(
              generatePath(
                ROUTES.MY_JOBS_AUTO_MANAGED_ID,
                { id: dataAutoManaged?.joinAutoManagedCampaign?.id },
                { [DIALOG_REQUIRED_ACTIONS.ADD_POSTS]: true }
              )
            );
          }, 100);
        } else if (isTikTokSpecialCampaign) {
          await joinTikTokSpecialCampaign({
            variables: { input: { campaignId, socialAccountId: selectedMethodIds[0] } },
          });
          setTimeout(() => {
            navigate(
              generatePath(
                ROUTES.MY_JOBS_TIKTOK_SPECIAL_ID,
                { id: campaignId },
                { [DIALOG_REQUIRED_ACTIONS.ADD_POSTS]: true }
              )
            );
          }, 100);
        } else {
          await joinMarketplaceV2({
            variables: {
              input: {
                campaignId,
                ...(isPostTracking
                  ? {
                      socialAccountId: selectedMethodIds[0],
                      socialMedia: selectedMethods[0] as SocialAccountType,
                    }
                  : {
                      methods: selectedMethods as CampaignPromotionMethod[],
                    }),
              },
            },
          });
          setTimeout(() => {
            navigate(
              generatePath(
                ROUTES.MY_JOBS_MARKETPLACE_ID,
                { id: campaignId },
                {
                  ...(isSelectionCampaign
                    ? { [DIALOG_REQUIRED_ACTIONS.JOINED_SELECTION]: true }
                    : { ...(!requireDraftPost && { [DIALOG_REQUIRED_ACTIONS.ADD_POSTS]: true }) }),
                }
              )
            );
          }, 100);
        }
      } catch (err) {
        enqueueSnackbar(t(err.message), { variant: 'error' });
      } finally {
        setIsJoining(false);
        setDialog(DialogStatus.CLOSE);
      }
    }
  };

  const onClickRedirectSocialConnect = () => {
    // to remove dialog lock scroll
    setDialog(DialogStatus.CLOSE);
    // TODO: need redirect to my page social connect after migrate to new UI
    setRecoilState({
      ...recoilState,
      previousPath: generatePath(
        isAutoManagedCampaign
          ? ROUTES.FIND_JOBS_AUTO_MANAGED_ID
          : isWaiting
          ? ROUTES.MY_JOBS_WAITING_ID
          : ROUTES.FIND_JOBS_MARKETPLACE_ID,
        { id: searchId }
      ),
    });
    // redirect after dialog closed 0.1s
    setTimeout(() => {
      navigate(ROUTES.MY_PAGE_SOCIAL_CONNECT);
    }, 100);
  };

  const onClickSelectMethodIds = (methodId: number, type: SocialAccountType) => {
    let methodIds = [...selectedMethodIds];
    if (!isPostTracking) {
      const index = methodIds.findIndex(itemId => itemId === methodId);
      if (index > -1) {
        methodIds.splice(index, 1);
      } else {
        methodIds.push(methodId);
      }
    } else {
      methodIds = [methodId];
      setSelectedMethods([type]);
    }
    setSelectedMethodIds(methodIds);
  };

  const onClickSelectMethods = (method: CampaignPromotionMethod) => {
    const methods = [...selectedMethods];
    const index = methods.findIndex(m => m === method);
    if (index > -1) {
      methods.splice(index, 1);
    } else {
      methods.push(method);
    }
    setSelectedMethods(methods);
  };

  const onClickTiktokReconnect = (tiktokId: string) => {
    tiktokReconnectSocialAuth(SocialAccountType.TIKTOK, { marketplaceId: searchId, socialAccountId: Number(tiktokId) });
  };

  const onCloseDialog = () => {
    setDialog(DialogStatus.CLOSE);
    clearErrors();
    setValue('phoneNumber', influencerPhoneNumber);
    setSelectedMethodIds([]);
    setSelectedMethods([]);
  };

  return (
    <div css={styles.container}>
      <Dialog
        contentRef={dialogContent}
        contentStyles={{
          backgroundColor: THEME.colors.white,
          borderRadius: 9,
          boxShadow: THEME.box.shadows.outer,
          height:
            dialog === DialogStatus.REGISTER_PHONE_NUMBER
              ? isMobileView
                ? 410
                : 366
              : dialog === DialogStatus.CONFIRM_PHONE_NUMBER
              ? isMobileView
                ? 309
                : 315
              : 548,
          maxWidth: 648,
          width: '90%',
        }}
        open={dialog !== DialogStatus.CLOSE}
        onClose={onCloseDialog}
        {...(isJoinableAccounts
          ? {
              cancelButtonProps: {
                text: 'Close',
                onClick: onCloseDialog,
              },
              nextButtonProps: {
                disabled:
                  (dialog === DialogStatus.CHOOSE_PROMOTION_METHODS &&
                    !(selectedMethodIds.length || selectedMethods.length)) ||
                  (dialog === DialogStatus.REGISTER_PHONE_NUMBER && !phoneNumber) ||
                  isJoining,
                text:
                  dialog === DialogStatus.CONFIRM_PHONE_NUMBER
                    ? 'Confirm'
                    : dialog === DialogStatus.REGISTER_PHONE_NUMBER
                    ? 'Register'
                    : 'Next',
                onClick: onClickNext,
              },
            }
          : isMobileView
          ? {
              nextButtonProps: {
                postfixIcon: <Arrow>&#8963;</Arrow>,
                text: 'Connect Social Accounts',
                onClick: onClickRedirectSocialConnect,
              },
            }
          : {})}
      >
        <form css={styles.dialogContent}>
          {dialog === DialogStatus.CONNECT_SOCIAL_ACCS ? (
            <div css={styles.emptyJoinableMethods}>
              <div>
                <div>{t('Dialog.Connect your Social Accounts')}</div>
                <div>{t('Annotation.Please connect Social Account from setting page')}</div>

                {isCampaignPromotionMethods && (
                  <div css={styles.campaignSocialMedias}>
                    <div>{t('Annotation.This campaign can be joined by')}</div>
                    <div>
                      {socialMedias.map(socialMedia => (
                        <div css={styles.socialMediaIndicatorContainer} key={socialMedia}>
                          {socialMedia === CampaignSocialMediaType.OTHER ? (
                            <div>{t('Option.Others (Email/Offline/Website)')}</div>
                          ) : socialMedia === CampaignSocialMediaType.INSTAGRAM_STORY ? (
                            <>
                              <Icomoon icon={switchImage({ socialMedia })} size={16} />
                              <div>{switchText(socialMedia)}</div>
                            </>
                          ) : (
                            <>
                              <SocialMediaIndicator
                                color={switchSocialBackgroundColor(socialMedia)}
                                isGradient={socialMedia === CampaignSocialMediaType.INSTAGRAM}
                              >
                                <Icomoon
                                  color="#fff"
                                  icon={switchImage({ dynamicColor: true, simplify: true, socialMedia })}
                                  size={10}
                                />
                              </SocialMediaIndicator>
                              <div>{switchText(socialMedia)}</div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {!isMobileView && (
                  <div css={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
                    <ThemeButton
                      css={{ height: 44, width: 220 }}
                      customPalette={mainBlack}
                      postfixIcon={<Arrow>&#8963;</Arrow>}
                      text="Connect Social Accounts"
                      onClick={onClickRedirectSocialConnect}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : dialog === DialogStatus.TERMS_CONDITIONS ? (
            <>
              <DialogTitle>{t('TermsConditionsPayment')}</DialogTitle>
              <TermsConditionList isRtl={isRtl}>
                {endDate && startDate && (
                  <li>
                    {t('TheCampaignPeriodIs', {
                      start: localizedDateFormatter(startDate, 'PPP', i18n.language as AppLanguage),
                      end: localizedDateFormatter(endDate, 'PPP', i18n.language as AppLanguage),
                    })}
                  </li>
                )}
                {maximumRevenuePerInfluencer && (
                  <li>
                    {t('RevenueLimitGuideLine', {
                      price: formatNumberWithCommas(maximumRevenuePerInfluencer, undefined, currency || undefined),
                      currency: currency ? t(currency) : '',
                    })}
                  </li>
                )}
                <li css={{ color: '#ff5f5f !important', fontWeight: 600 }}>
                  {t('RevenueQuestionMask', {
                    price: formatNumberWithCommas(minimumPaymentAmount, undefined, currency || undefined),
                    currency: currency ? t(currency) : '',
                  })}
                </li>
                <li>{t('Terms1')}</li>
                <li>{t('Terms2')}</li>
                <li>{t('Terms3')}</li>
                <li>{t('Terms4')}</li>
              </TermsConditionList>
            </>
          ) : dialog === DialogStatus.CHOOSE_PROMOTION_METHODS ? (
            <>
              <DialogTitle>{t('Dialog.Which methods do you use to promote')}</DialogTitle>
              <DialogDescription css={{ borderBottom: '1px solid #eef3f7', paddingBottom: 16 }}>
                {t('ChooseJoiningAccountExplained')}
              </DialogDescription>

              {!isPostTracking && !isTikTokSpecialCampaign ? (
                <div css={{ display: 'grid', gap: THEME.box.gaps.s }}>
                  {promotionMethods.map(({ icon, title, type }) => {
                    const isActive = selectedMethods.includes(type);
                    const isInstagram = [
                      CampaignPromotionMethod.INSTAGRAM,
                      CampaignPromotionMethod.INSTAGRAM_STORY,
                    ].includes(type);

                    return (
                      <PromotionMethodCard isActive={isActive} key={type} onClick={() => onClickSelectMethods(type)}>
                        <SocialMediaIndicator
                          color={
                            title
                              ? THEME.colors.gray.main
                              : switchSocialBackgroundColor(isInstagram ? CampaignPromotionMethod.INSTAGRAM : type)
                          }
                          isGradient={isInstagram}
                          size={24}
                        >
                          <Icomoon color="#fff" icon={icon} size={14} />
                        </SocialMediaIndicator>
                        <div>{title ? t(title) : switchText(type)}</div>
                      </PromotionMethodCard>
                    );
                  })}
                </div>
              ) : (
                <>
                  <div css={{ display: 'grid', gap: THEME.box.gaps.s, marginBottom: 16 }}>
                    {joinableAccounts.map(
                      ({
                        activeToken,
                        avatar,
                        disabled: socialAccountDisabled,
                        followersCount,
                        id: accountId,
                        name,
                        type,
                      }) => {
                        const isActive = selectedMethodIds.includes(accountId);

                        return (
                          <Card
                            disabled={!!socialAccountDisabled}
                            isActive={isActive}
                            key={accountId}
                            onClick={() =>
                              !activeToken
                                ? type === SocialAccountType.TIKTOK
                                  ? onClickTiktokReconnect(String(accountId))
                                  : onClickConnect(type as SocialAccountType)
                                : onClickSelectMethodIds(accountId, type as SocialAccountType)
                            }
                          >
                            {socialAccountDisabled ? (
                              <SocialMediaOverlay disabled>
                                {t(`It's not match the condition Account`)}
                              </SocialMediaOverlay>
                            ) : !activeToken ? (
                              <SocialMediaOverlay>
                                <div css={{ textAlign: 'center', width: 'fill-available' }}>
                                  {t(`Annotation.Tap to reconnect this account`)}
                                </div>
                                <div css={{ color: THEME.font.colors.gray.main, fontSize: THEME.font.sizes.heading }}>
                                  &#8594;
                                </div>
                              </SocialMediaOverlay>
                            ) : null}

                            <div css={{ position: 'relative' }}>
                              <Avatar size={40} src={avatar} title={name} />
                              <SocialMediaIndicator
                                color={switchSocialBackgroundColor(type)}
                                isAbsolute
                                isGradient={type === SocialAccountType.INSTAGRAM}
                              >
                                <Icomoon
                                  color="#fff"
                                  icon={switchImage({
                                    dynamicColor: true,
                                    simplify: true,
                                    socialMedia: type,
                                  })}
                                  size={10}
                                />
                              </SocialMediaIndicator>
                            </div>
                            <div>
                              <div>{name}</div>
                              {type !== SocialAccountType.TIKTOK && (
                                <TextCutter
                                  lines={1}
                                  text={t('Total followers', {
                                    count: formatNumberWithCommas(followersCount, 0) as never,
                                  })}
                                />
                              )}
                            </div>
                          </Card>
                        );
                      }
                    )}
                  </div>

                  <div css={styles.addSocialAccounts} onClick={onClickRedirectSocialConnect}>
                    &#43;{t('Button.Add Social Accounts')}
                  </div>
                </>
              )}
            </>
          ) : dialog === DialogStatus.REGISTER_PHONE_NUMBER ? (
            <>
              <DialogTitle>{t('Title.Register your phone number to join the campaign')}</DialogTitle>
              <label css={styles.dialogRegisterPhoneNumberDesc}>
                {t('Annotation.There may be cases where staff will contact you')}
              </label>
              <TextFormV2
                error={!!errors.phoneNumber}
                isRequired
                placeholder="01201234567"
                title="Phone Number"
                type="number"
                {...register('phoneNumber')}
              />
              {errors.phoneNumber && <ErrorMessage message={errors.phoneNumber.message} />}
            </>
          ) : dialog === DialogStatus.CONFIRM_PHONE_NUMBER ? (
            <div css={styles.dialogConfirmPhoneNumber}>
              <div className="title">
                <Icomoon color="#f8b74a" icon="warning" size={20} />
                <label>{t('Title.Are you sure to update with this phone number')}</label>
              </div>
              <div className="phone-number-container">
                <label className="subtitle">{t('Phone Number')}</label>
                <label className="phone-number">{phoneNumber}</label>
              </div>
            </div>
          ) : null}
        </form>
      </Dialog>
      <ThemeButton
        css={styles.joinBtn}
        customPalette={mainBlack}
        disabled={disabled}
        text={isSelectionCampaign ? 'Apply to this campaign' : 'Join Campaign'}
        onClick={() => setDialog(isJoinableAccounts ? DialogStatus.TERMS_CONDITIONS : DialogStatus.CONNECT_SOCIAL_ACCS)}
      />
    </div>
  );
};

const Arrow = styled.div({
  fontSize: THEME.font.sizes.subHeading,
  fontWeight: 600,
  transform: 'rotate(90deg)',
});

const Card = styled.div<{ disabled: boolean; isActive: boolean }>(({ disabled, isActive }) => ({
  alignItems: 'center',
  backgroundColor: isActive ? '#f6f8fa' : '#fff',
  border: `1px solid ${isActive ? '#27313b' : '#dee5ec'}`,
  borderRadius: 9,
  cursor: 'pointer',
  display: 'flex',
  gap: THEME.box.gaps.l,
  padding: 12,
  position: 'relative',
  ...(disabled && { pointerEvents: 'none' }),

  '& > div:last-child': {
    display: 'grid',
    gap: 2,

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
    },

    '& > div:nth-of-type(2)': {
      color: THEME.font.colors.black.main,
      fontSize: 11,
    },
  },
}));

const DialogDescription = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.normal,
  marginBottom: 16,
});

const DialogTitle = styled.div({
  color: THEME.font.colors.black.main,
  fontSize: THEME.font.sizes.subTitle,
  fontWeight: 600,
  marginBottom: 16,
});

const PromotionMethodCard = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  alignItems: 'center',
  backgroundColor: isActive ? '#f6f8fa' : '#fff',
  border: `1px solid ${isActive ? '#27313b' : '#dee5ec'}`,
  borderRadius: 9,
  cursor: 'pointer',
  display: 'flex',
  gap: THEME.box.gaps.m,
  padding: '12px 16px',

  '& > div:nth-of-type(2)': {
    color: THEME.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
  },
}));

const SocialMediaIndicator = styled.div<{
  color: string;
  isAbsolute?: boolean;
  isGradient: boolean;
  size?: number;
}>(({ color, isAbsolute, isGradient, size = 16 }) => ({
  alignItems: 'center',
  border: '2px solid #fff',
  borderRadius: '50%',
  display: 'flex',
  height: size,
  justifyContent: 'center',
  width: size,
  ...(isGradient ? { backgroundImage: color } : { backgroundColor: color }),
  ...(isAbsolute && { bottom: 0, position: 'absolute', right: -8 }),
}));

const SocialMediaOverlay = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  alignItems: 'center',
  background: disabled ? 'rgba(39, 49, 59, 0.3)' : 'rgba(255, 255, 255, 0.8)',
  borderRadius: 9,
  color: disabled ? '#fff' : '#27313b',
  display: 'flex',
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
  gap: THEME.box.gaps.s,
  height: '100%',
  justifyContent: 'center',
  left: 0,
  padding: '0 18px',
  position: 'absolute',
  top: 0,
  width: 'fill-available',
  zIndex: 1,
  ...(disabled && { pointerEvents: 'none' }),
}));

const TermsConditionList = styled.ul<{ isRtl: boolean }>(({ isRtl }) => ({
  display: 'grid',
  gap: THEME.box.gaps.m,

  '& > li': {
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    listStyle: 'disc',
    ...(isRtl ? { marginRight: 16 } : { marginLeft: 16 }),
  },
}));

const styles = {
  addSocialAccounts: css({
    alignItems: 'center',
    color: THEME.font.colors.black.main,
    cursor: 'pointer',
    display: 'flex',
    fontSize: THEME.font.sizes.subHeading,
    fontWeight: 600,
    gap: THEME.box.gaps.xs,
    justifyContent: 'flex-end',
  }),
  container: css({
    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      width: 'fill-available',
    },
  }),
  dialogConfirmPhoneNumber: css({
    display: 'grid',

    '& > .title': {
      alignItems: 'flex-start',
      color: THEME.font.colors.black.main,
      display: 'flex',
      fontSize: THEME.font.sizes.subTitle,
      fontWeight: 600,
      gap: THEME.box.gaps.s,
      marginBottom: 32,
    },

    '& > .phone-number-container': {
      borderBottom: THEME.box.borders.gray.eef3f7,
      borderTop: THEME.box.borders.gray.eef3f7,
      color: THEME.colors.black.main,
      display: 'grid',
      gap: THEME.box.gaps.xs,
      justifySelf: 'center',
      maxWidth: 428,
      padding: '16px 0',
      textAlign: 'center',
      width: '100%',

      '& > .subtitle': {
        fontSize: THEME.font.sizes.normal,
      },

      '& > .phone-number': {
        fontSize: THEME.font.sizes.subTitle,
        fontWeight: 600,
      },
    },
  }),
  dialogContent: css({
    height: 'fill-available',
    overflow: 'auto',
    padding: '32px 8px',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      padding: '24px 0',
    },
  }),
  dialogRegisterPhoneNumberDesc: css({
    borderBottom: THEME.box.borders.gray.eef3f7,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
    paddingBottom: 16,
    whiteSpace: 'pre-line',
  }),
  emptyJoinableMethods: css({
    alignItems: 'center',
    display: 'grid',
    height: 'fill-available',
    justifyContent: 'center',

    '& > div': {
      maxWidth: 387,
      padding: '0 16px',
      textAlign: 'center',

      '& > div:nth-of-type(1)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.heading,
        fontWeight: 600,
        marginBottom: 24,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.normal,
        whiteSpace: 'pre-line',
      },
    },
  }),
  campaignSocialMedias: css({
    borderBottom: '1px solid #eef3f7',
    borderTop: '1px solid #eef3f7',
    display: 'grid',
    gap: THEME.box.gaps.xs,
    justifyContent: 'center',
    marginTop: 24,
    padding: '12px 0',

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      marginTop: 16,
      padding: '8px 0',
    },

    '& > div': {
      display: 'flex',
      flexBasis: '100%',
      gap: THEME.box.gaps.l,
      justifyContent: 'center',
    },

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subordinate,
    },
  }),
  joinBtn: css({
    borderRadius: 9,
    height: 40,
    width: 226,

    [`@media (max-width: ${ViewportType.TABLET}px)`]: {
      height: 56,
      width: 'fill-available',

      '& span': {
        fontSize: THEME.font.sizes.subHeading,
      },
    },
  }),
  socialMediaIndicatorContainer: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.xs,

    '& > div:last-of-type': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subHeading,
      fontWeight: 600,
    },
  }),
};

export default JoinButton;
