import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Icomoon } from '@src/components/atoms';
import { useAuthData, useGlobalLayout, usePageLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ROUTES } from '@src/shared/routes';

const YoutubeCmsWidget = () => {
  const { isYoutubeCmsRevenue } = useAuthData();
  const { isMobileView } = usePageLayout();
  const { hasLineWidget, hasMobileMenu, setGlobalLayout } = useGlobalLayout();
  const { t } = useTranslation();
  const hasYoutubeCmsWidget = isMobileView && isYoutubeCmsRevenue;

  useEffect(() => {
    setGlobalLayout({ hasYoutubeCmsWidget });
  }, [hasYoutubeCmsWidget]);

  return hasYoutubeCmsWidget ? (
    <Container hasLineWidget={hasLineWidget} hasMobileMenu={hasMobileMenu} to={ROUTES.YOUTUBE_CMS_REVENUE}>
      <Icomoon color={THEME.colors.white} icon="music-note-single-bar" size={16} />
      <label>{t('Title.Back to Music Report')}</label>
      <Icomoon color={THEME.colors.white} icon="arrow-up-right" size={12} />
    </Container>
  ) : null;
};

const Container = styled(Link, { shouldForwardProp: prop => isPropValid(prop) })<{
  hasLineWidget: boolean;
  hasMobileMenu: boolean;
}>(({ hasLineWidget, hasMobileMenu }) => ({
  '--lineWidgetWidth': '72px',

  alignItems: 'center',
  background: THEME.colors.black.main,
  borderRadius: 50,
  bottom: hasMobileMenu ? 76 : 12,
  color: THEME.font.colors.white,
  cursor: 'pointer',
  display: 'flex',
  fontSize: THEME.font.sizes.normal,
  fontWeight: 600,
  gap: THEME.box.gaps.s,
  left: 12,
  padding: 16,
  position: 'fixed',
  width: hasLineWidget ? 'calc(100% - var(--lineWidgetWidth) - 36px - 32px)' : 'calc(100% - 24px - 32px)',
  zIndex: 2,

  '& > svg:nth-of-type(1)': {
    minWidth: 16,
  },

  '& > label': {
    width: 'fill-available',
  },

  '& > svg:nth-of-type(2)': {
    minWidth: 12,
  },
}));

export default YoutubeCmsWidget;
