import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  bluesImg,
  cinematicImg,
  classicalImg,
  dramaticImg,
  edmImg,
  funkImg,
  funnyImg,
  happyImg,
  jazzImg,
  lofiImg,
  medievalImg,
  mysteryImg,
  phonkEDMImg,
  reportImg,
  rockImg,
  sadImg,
  seasonalImg,
  soulImg,
  synthImg,
} from '@src/assets/img/youtubeCMSMusic';
import { YoutubeCmsServerError } from '@src/components/atoms';
import { ListLoading, NotificationBadge, PagerV2 } from '@src/components/molecules';
import { getPageInfo } from '@src/components/molecules/Pager/helpers';
import { SearchFormV2 } from '@src/components/shared';
import { useYoutubeMusicCategoriesQuery, useYoutubeMusicListQuery } from '@src/graphql/hooks';
import { LIMIT_10 } from '@src/libs/constant';
import { ErrorTypes } from '@src/libs/error';
import { sliceItemsOnPage } from '@src/libs/functions';
import { usePageLayout, useUrl } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { lineWidgetState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import MusicPlayer from './MusicPlayer';

enum Tabs {
  ALL_MUSIC = 'ALL_MUSIC',
  CATEGORY = 'CATEGORY',
}

const YoutubeCmsMusic = () => {
  const [currTab, setCurrTab] = useState<Tabs>(Tabs.CATEGORY);
  const [keyword, setKeyword] = useState<string>('');
  const [selectedMusicId, setSelectedMusicId] = useState<number | null>(null);
  const [tempKeyword, setTempKeyword] = useState<string>('');
  const { isMobileView } = usePageLayout();
  const { setRecoilState } = useRecoil(lineWidgetState);
  const { t } = useTranslation();
  const { searchParams, updateSearchParams } = useUrl();
  const mapping: Record<string, string> = {
    Sad: sadImg,
    Report: reportImg,
    Funny: funnyImg,
    Dramatic: dramaticImg,
    Happy: happyImg,
    Classical: classicalImg,
    Blues: bluesImg,
    Cinematic: cinematicImg,
    Soul: soulImg,
    Jazz: jazzImg,
    Funk: funkImg,
    Rock: rockImg,
    EDM: edmImg,
    'Phonk EDM': phonkEDMImg,
    Mystery: mysteryImg,
    Seasonal: seasonalImg,
    'Lo-Fi': lofiImg,
    Synth: synthImg,
    Medieval: medievalImg,
  };
  const page = Number(searchParams.get('p')) || 1;
  const tabs = [
    { title: 'Category', value: Tabs.CATEGORY },
    { title: 'All Music', value: Tabs.ALL_MUSIC },
  ];

  const { data, loading } = useYoutubeMusicCategoriesQuery();
  const categories = data?.youtubeMusicCategories || [];

  const {
    data: dataMusicList,
    error: errorMusicList,
    loading: loadingMusicList,
  } = useYoutubeMusicListQuery({
    skip: currTab !== Tabs.ALL_MUSIC,
    variables: {
      ...(keyword && { keyword }),
    },
  });
  const isServerError = errorMusicList?.message === ErrorTypes.DATA_TIMEOUT;
  const musicList = dataMusicList?.youtubeMusicList || [];
  const pageInfo = getPageInfo(page, musicList.length, LIMIT_10);

  useEffect(() => {
    setRecoilState({ isHidden: true });

    return () => {
      setRecoilState({ isHidden: false });
    };
  }, []);

  useEffect(() => {
    setSelectedMusicId(null);
  }, [page]);

  const onClickTab = (tab: Tabs) => {
    setSelectedMusicId(null);
    setCurrTab(tab);
    updateSearchParams({ p: 1 });
  };

  const onEnterKeyPress = () => {
    setKeyword(tempKeyword);
    setCurrTab(Tabs.ALL_MUSIC);
    updateSearchParams({ p: 1 });
  };

  if (!loading && !categories?.length && !isServerError) {
    return <Navigate to={ROUTES.YOUTUBE_CMS_REVENUE} />;
  }

  return (
    <div
      css={{
        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          display: 'grid',
          gap: THEME.box.gaps.l,
        },
      }}
    >
      <div css={styles.toolbar}>
        <label>{t('Title.Download Music')}</label>
        <div className="badge-container">
          <NotificationBadge />
        </div>
      </div>

      <div css={styles.contentContainer}>
        <div className="search-field-container">
          <SearchFormV2
            placeholder={t('Placeholder.Search Music Name')}
            value={tempKeyword}
            onChange={e => setTempKeyword(e.target.value)}
            onEnterKeyPress={onEnterKeyPress}
          />
        </div>
        <div className="tabs-container">
          {tabs.map(({ title, value }) => {
            const isActive = value === currTab;

            return (
              <Tab key={value} isActive={isActive} onClick={() => onClickTab(value)}>
                {t(`Tab.${title}`)}
              </Tab>
            );
          })}
        </div>
        {currTab === Tabs.CATEGORY ? (
          loading ? (
            <ListLoading isTransparentBg />
          ) : (
            <div className="categories-container">
              {[...categories]
                .sort((a, b) => {
                  const orders = Object.keys(mapping);

                  return orders.indexOf(a.category) - orders.indexOf(b.category);
                })
                .map(({ category, id }) => {
                  const backgroundImage = mapping[category];

                  return (
                    <Link
                      css={{ backgroundImage: `url(${backgroundImage})` }}
                      key={id}
                      to={generatePath(ROUTES.YOUTUBE_CMS_MUSIC_CATEGORYID, { categoryId: id })}
                    >
                      {t(`Option.${category}`)}
                    </Link>
                  );
                })}
            </div>
          )
        ) : loadingMusicList ? (
          <ListLoading isTransparentBg />
        ) : isServerError ? (
          <div css={styles.serverErrorContainer}>
            <YoutubeCmsServerError pageTitle="Music" />
          </div>
        ) : (
          <div css={styles.musicListContainer}>
            {(isMobileView ? musicList : sliceItemsOnPage(musicList, page)).map(music => {
              const isSelected = music.id === selectedMusicId;

              return (
                <MusicPlayer
                  {...music}
                  isSelected={isSelected}
                  key={music.id}
                  onClickSelectPlayback={() => setSelectedMusicId(music.id)}
                />
              );
            })}
          </div>
        )}
      </div>

      {!isMobileView && !isServerError && (
        <PagerV2
          css={{ justifySelf: 'center', marginBottom: 24 }}
          currentPage={page}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
          totalCount={pageInfo.totalCount}
          totalPages={pageInfo.totalPages}
        />
      )}
    </div>
  );
};

const Tab = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  color: isActive ? THEME.font.colors.black.main : THEME.font.colors.gray.a8b4bf,
  cursor: 'pointer',
  display: 'grid',
  fontSize: THEME.font.sizes.subHeading,
  fontWeight: 600,
  justifyContent: 'center',
  padding: 16,
  width: 'fill-available',
  ...(isActive && { borderBottom: '2px solid #27313b' }),
}));

const styles = {
  contentContainer: css({
    justifySelf: 'center',
    maxWidth: 612,
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.m,
      overflow: 'hidden',
    },

    '& > .search-field-container': {
      background: THEME.colors.white,
      padding: '12px 16px 0 16px',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        padding: '24px 24px 0 24px',
      },
    },

    '& > .tabs-container': {
      background: THEME.colors.white,
      display: 'flex',
      padding: '0 16px',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        padding: '0 24px',
      },
    },

    '& > .categories-container': {
      display: 'grid',
      gap: THEME.box.gaps.s,
      gridTemplateColumns: 'repeat(2, 1fr)',
      padding: 16,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        padding: 24,
      },

      '& > a': {
        alignItems: 'center',
        backgroundColor: THEME.colors.disabled,
        backgroundSize: 'cover',
        borderRadius: THEME.box.borderRadius.m,
        cursor: 'pointer',
        color: THEME.font.colors.white,
        display: 'grid',
        fontFamily: 'Poppins',
        fontSize: 13,
        fontWeight: 600,
        height: 72,
        justifyContent: 'center',
        overflow: 'hidden',
        textTransform: 'uppercase',
        width: 'fill-available',
      },
    },
  }),
  musicListContainer: css({
    background: THEME.colors.gray.eef3f7,
    borderTop: THEME.box.borders.gray.eef3f7,
    display: 'grid',
    gap: 1,
  }),
  serverErrorContainer: css({
    alignItems: 'center',
    display: 'grid',
    height: 400,
    justifyContent: 'center',
    width: '100%',
  }),
  toolbar: css({
    alignItems: 'center',
    background: THEME.colors.white,
    display: 'flex',
    gap: THEME.box.gaps.s,
    padding: '8px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '32px 40px',

      '&::before': {
        content: '""',
        display: 'block',
        width: 'fill-available',
      },
    },

    '& > label': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.heading,
      fontWeight: 600,
      width: 'fill-available',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        minWidth: 614,
      },
    },

    '& > .badge-container': {
      display: 'grid',
      justifyContent: 'flex-end',
      width: 'fill-available',
    },
  }),
};

export default YoutubeCmsMusic;
