import * as React from 'react';
import { useTranslation } from 'react-i18next';
import HighChart, { Highcharts } from '@src/components/atoms/HighChart';
import DataOpacityWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { formatPercent } from '@src/libs/format';
import { useDirLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { TooltipTemplateSmall } from '../TooltipTemplates';
import { FollowerGenderRate } from './types';

const GenderBreakdown = (props: FollowerGenderRate) => {
  const { femaleRate, maleRate, otherRate, className } = props;
  const { t } = useTranslation();
  const { dir, isRtl } = useDirLayout();

  const options: Highcharts.Options = {
    chart: {
      height: 273,
      marginBottom: 35,
      plotShadow: false,
      type: 'pie',
    },
    legend: {
      enabled: true,
      symbolRadius: 2,
      itemStyle: {
        fontSize: '13px',
        lineHeight: '1.38',
      },
      rtl: isRtl,
    },
    title: {
      text: undefined,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          distance: '-20',
          enabled: true,
          formatter() {
            return formatPercent(this.y || null);
          },
          style: {
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '1.13',
          },
          shadow: {
            offsetX: 0,
            offsetY: 2,
            opacity: 0.2,
            width: 2,
            color: THEME.font.colors.black.main,
          },
          borderRadius: 5,
          backgroundColor: 'white',
          color: THEME.font.colors.black.main,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        type: 'pie',
        innerSize: '50%',
        colorByPoint: true,
        name: t<string>('Gender'),
        data: [
          {
            name: t<string>('Female') as string,
            y: femaleRate,
            color: '#ff8a94',
          },
          {
            name: t<string>('Male') as string,
            y: maleRate,
            color: '#707fc0',
          },
          ...(otherRate && otherRate > 0
            ? [
                {
                  name: t<string>('Other') as string,
                  y: otherRate,
                  color: '#ffca98',
                },
              ]
            : []),
        ],
      },
    ],
    tooltip: {
      padding: 0,
      formatter(): string {
        return TooltipTemplateSmall({
          seriaName: t(`BasicInfo.${this.series.name}`) as string,
          point: this.point,
          name: this.key?.toString() || '',
          dir,
        });
      },
      pointFormat: '',
      borderColor: '#27313b',
      borderWidth: 0,
      backgroundColor: THEME.colors.black.main,
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      headerFormat: '',
    },
  };

  return (
    <DataOpacityWrapper
      header={<SectionHeader>{t('Title.Gender Breakdown')}</SectionHeader>}
      dataNotAvailable={!femaleRate && !maleRate && !otherRate}
      className={className}
    >
      <HighChart options={options} />
    </DataOpacityWrapper>
  );
};

export default GenderBreakdown;
