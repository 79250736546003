import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  GenerateTikTokSpecialPostCaptionMutation,
  GenerateTikTokSpecialPostCaptionMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/GenerateTikTokSpecialPostCaption.graphql';

export const useGenerateTikTokSpecialPostCaptionMutation = (
  options?: MutationHookOptions<
    GenerateTikTokSpecialPostCaptionMutation,
    GenerateTikTokSpecialPostCaptionMutationVariables
  >
) =>
  useMutation<GenerateTikTokSpecialPostCaptionMutation, GenerateTikTokSpecialPostCaptionMutationVariables>(
    MUTATION,
    options
  );
