import { useLazyQuery, useQuery, LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
import { useQueryHelper } from '@src/libs/hooks';
import {
  TiktokSpecialJoinedAccountQuery,
  TiktokSpecialJoinedAccountQueryVariables,
} from '@src/__generated__/globalTypes';
import QUERY from '../queries/TiktokSpecialJoinedAccount.graphql';

export const useTiktokSpecialJoinedAccountQuery = (
  options?: QueryHookOptions<TiktokSpecialJoinedAccountQuery, TiktokSpecialJoinedAccountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useQuery<TiktokSpecialJoinedAccountQuery, TiktokSpecialJoinedAccountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};

export const useTiktokSpecialJoinedAccountLazyQuery = (
  options?: LazyQueryHookOptions<TiktokSpecialJoinedAccountQuery, TiktokSpecialJoinedAccountQueryVariables>
) => {
  const { enqueueSnackbar, t } = useQueryHelper();

  return useLazyQuery<TiktokSpecialJoinedAccountQuery, TiktokSpecialJoinedAccountQueryVariables>(QUERY, {
    onError: err => {
      enqueueSnackbar(t(err.message), { variant: 'error' });
    },
    ...options,
  });
};
