import { css } from '@emotion/react';
import React from 'react';
import { THEME } from '@src/libs/theme';
import { getGrowthArrow, getGrowthPrefix } from './utils';

export const IncrementSection = ({ followersIncrement }: { followersIncrement: number }) => {
  const incrementArrow = getGrowthArrow(followersIncrement);
  const prefix = getGrowthPrefix(followersIncrement);

  return followersIncrement ? (
    <span css={[styles.textOverflow, { color: followersIncrement > 0 ? '#40b87c' : '#ff2b52' }]}>
      {`${prefix} ${followersIncrement} ${incrementArrow}`}
    </span>
  ) : null;
};

export const styles = {
  tooltip: css({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 3,
    border: '2px solid #27313b',
    backgroundColor: THEME.colors.black.main,
    fontSize: THEME.font.sizes.subordinate,
    padding: '13px 16px',
    minWidth: 300,
  }),
  heading: css({
    marginBottom: 8,
    fontSize: 13,
    color: THEME.font.colors.white,
  }),
  grid: css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(80px, auto))',
    columnGap: 2,
    rowGap: 8,
  }),
  row: css({
    display: 'flex',
    marginTop: 12,
  }),
  rowItem: css({
    minHeight: 32,
    display: 'flex',
    gap: THEME.box.gaps.xs,
  }),
  rowTitle: css({
    color: '#c5d0da',
    fontWeight: 400,
    fontSize: 11,
  }),
  rowData: css({
    fontWeight: 400,
    fontSize: 13,
    color: THEME.font.colors.white,
    display: 'flex',
    flexDirection: 'row',
    gap: THEME.box.gaps.xs,
  }),
  textOverflow: css({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-all',
  }),
  numbersWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  }),
  verticalLine: css({
    height: '100%',
    width: 1,
    background: '#6e7c89',
  }),
  avatar: css({
    width: 24,
    height: 24,
    objectFit: 'contain',
    border: '1px solid #27313b',
    borderRadius: '50%',
  }),
  name: css({
    alignSelf: 'center',
    fontSize: 13,
    fontWeight: 600,
    color: THEME.font.colors.white,
  }),
};
