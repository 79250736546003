import { ApolloError } from '@apollo/client';
import { ExecutionResult } from 'graphql/execution';

export const UNEXPECTED_ERROR = 'UnexpectedError';
export const FAILED_TO_UPDATE = 'failedToUpdate';

export const getMessagesFromApolloError = (e: ApolloError): string[] => e.graphQLErrors.map(error => error.message);
export const getMessagesFromFetchResult = (e: ExecutionResult<any> | void): string[] =>
  e && e.errors ? e.errors.map(error => error.message) : [UNEXPECTED_ERROR];

export enum ErrorTypes {
  ACCOUNT_OR_PAGE_CONNECTED_TO_IG_BUSINESS_ACCOUNT = 'ACCOUNT_OR_PAGE_CONNECTED_TO_IG_BUSINESS_ACCOUNT',
  CAMPAIGN_CLOSED_OUT_OF_COUPON = 'CAMPAIGN_CLOSED_OUT_OF_COUPON',
  CAMPAIGN_FINISHED = 'CAMPAIGN_FINISHED',
  DATA_TIMEOUT = 'DATA_TIMEOUT',
  FB_REQUIRED_PERMISSION_MISSING = 'FB_REQUIRED_PERMISSION_MISSING',
  QUOTA_EXCEED_LIMIT_FOR_BROADCAST = 'QUOTA_EXCEED_LIMIT_FOR_BROADCAST',
  SNS_ACCOUNT_JOINED_IN_PROGRESS_CAMPAIGN = 'SNS_ACCOUNT_JOINED_IN_PROGRESS_CAMPAIGN',
  VIDEO_PERMISSION_MISSING = 'VIDEO_PERMISSION_MISSING',
}
