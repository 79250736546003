import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icomoon, TextCutter } from '@src/components/atoms';
import { CURRENCY_SYMBOLS } from '@src/libs/constant';
import { formatNumberWithCommas, formatPercent } from '@src/libs/format';
import { usePageLayout } from '@src/libs/hooks';
import { switchImage } from '@src/libs/socialMedia';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { generatePath, ROUTES } from '@src/shared/routes';
import {
  CampaignSocialMediaType,
  CampaignStatusForInfluencer,
  CampaignType,
  MarketplaceCampaignDetailType,
  MarketplaceCampaignStatus,
  AnyXMarketplaceCampaignStatus,
} from '@src/__generated__/globalTypes';
import { isDateWithinTheRange, isNumber } from '../helpers';
import StatusIndicator from './StatusIndicator';

export interface Campaign {
  campaignDetailType?: MarketplaceCampaignDetailType;
  campaignStatus?: CampaignStatusForInfluencer | MarketplaceCampaignStatus | null;
  createdDate?: string;
  commissionRate?: number | null;
  currency?: string;
  id: number;
  isAutoInfluencerApproval?: boolean | null;
  isGiftingCampaign?: boolean | null;
  isPostTracking?: boolean | null;
  isSelectionCampaign?: boolean | null;
  isWaiting?: boolean;
  marketplaceCampaignType?: MarketplaceCampaignDetailType | null;
  minFollowers?: number | null;
  preLaunchDate?: string | null;
  revenuePerAction?: number | null;
  revenuePerClick?: number | null;
  revenuePerComment?: number | null;
  revenuePerFollower?: number | null;
  revenuePerLike?: number | null;
  revenuePerOrder?: number | null;
  revenuePerPost?: number | null;
  revenuePerShare?: number | null;
  revenuePerView?: number | null;
  rewardAmount?: number | null;
  socialMedias?: CampaignSocialMediaType[];
  startDate?: any;
  status?: AnyXMarketplaceCampaignStatus | null;
  thumbnail: string;
  title: string;
  type: CampaignType;
}

interface CardProps {
  campaign: Campaign;
  isFindJobs?: boolean;
  isFinished?: boolean;
}

const Card = ({ campaign, isFindJobs, isFinished }: CardProps) => {
  const {
    campaignDetailType,
    campaignStatus,
    createdDate,
    commissionRate,
    currency = 'JPY',
    id,
    isAutoInfluencerApproval,
    isGiftingCampaign,
    isPostTracking,
    isSelectionCampaign,
    isWaiting,
    marketplaceCampaignType,
    minFollowers,
    preLaunchDate,
    revenuePerAction,
    revenuePerClick,
    revenuePerComment,
    revenuePerFollower,
    revenuePerLike,
    revenuePerOrder,
    revenuePerPost,
    revenuePerShare,
    revenuePerView,
    rewardAmount,
    socialMedias = [CampaignSocialMediaType.UNSELECT],
    startDate,
    status,
    thumbnail,
    title,
    type,
  } = campaign;
  const { t } = useTranslation();
  const { isMobileView } = usePageLayout();
  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';
  const isEcommerceCampaign =
    campaignDetailType === MarketplaceCampaignDetailType.E_COMMERCE ||
    marketplaceCampaignType === MarketplaceCampaignDetailType.E_COMMERCE;
  const isNewCampaign = createdDate && isDateWithinTheRange(createdDate, 5);
  const isOverBudget = type === CampaignType.MARKETPLACE && campaignStatus === CampaignStatusForInfluencer.SUSPENDED;
  const isPreLaunchSelection = preLaunchDate && new Date(startDate) > new Date();
  const isSelection = isSelectionCampaign || campaignDetailType === MarketplaceCampaignDetailType.SELECTION;
  const isTikTokSpecialCampaign = type === CampaignType.TIKTOK_SPECIAL;
  const showStatus = campaignDetailType === MarketplaceCampaignDetailType.E_COMMERCE && isPostTracking && status;
  const socialMediaTypes = [
    CampaignSocialMediaType.FACEBOOK,
    CampaignSocialMediaType.INSTAGRAM,
    CampaignSocialMediaType.TIKTOK,
    CampaignSocialMediaType.TWITTER,
    CampaignSocialMediaType.YOUTUBE,
  ];
  const { isAnyMethodsCampaign, isSocialMediaCampaign } = socialMedias.reduce(
    (accumulation, socialMedia) => ({
      ...accumulation,
      isAnyMethodsCampaign: [CampaignSocialMediaType.UNSELECT].includes(socialMedia),
      isSocialMediaCampaign: [
        ...socialMediaTypes,
        CampaignSocialMediaType.INSTAGRAM_STORY,
        CampaignSocialMediaType.UNSELECT,
      ].includes(socialMedia),
    }),
    {
      isAnyMethodsCampaign: false,
      isSocialMediaCampaign: false,
    }
  );

  const revenues = [
    ...(isNumber(revenuePerAction) ? [{ title: 'RevenuePer.Action', value: revenuePerAction }] : []),
    ...(isNumber(revenuePerClick) ? [{ title: 'RevenuePer.Click', value: revenuePerClick }] : []),
    ...(isNumber(revenuePerComment) ? [{ title: 'RevenuePer.Comment', value: revenuePerComment }] : []),
    ...(isNumber(revenuePerFollower) ? [{ title: 'RevenuePer.Follower', value: revenuePerFollower }] : []),
    ...(isNumber(revenuePerLike) ? [{ title: 'RevenuePer.Like', value: revenuePerLike }] : []),
    ...(isNumber(revenuePerOrder) ? [{ title: 'RevenuePer.Order', value: revenuePerOrder }] : []),
    ...(isNumber(revenuePerPost) ? [{ title: 'RevenuePer.Post', value: revenuePerPost }] : []),
    ...(isNumber(revenuePerShare) ? [{ title: 'RevenuePer.Share', value: revenuePerShare }] : []),
    ...(isNumber(revenuePerView) ? [{ title: 'RevenuePer.View', value: revenuePerView }] : []),
    ...(isNumber(commissionRate) ? [{ isPercentage: true, title: 'Commission rate', value: commissionRate }] : []),
    ...(isNumber(rewardAmount) ? [{ title: 'Reward Amount', value: rewardAmount }] : []),
  ];
  const isRevenue = !!revenues.length;

  return (
    <Link
      css={styles.card}
      to={generatePath(
        type === CampaignType.AUTO_MANAGED
          ? isFindJobs
            ? ROUTES.FIND_JOBS_AUTO_MANAGED_ID
            : ROUTES.MY_JOBS_AUTO_MANAGED_ID
          : type === CampaignType.ENGAGEMENT
          ? ROUTES.MY_JOBS_CASTING_YOUR_JOB_ID
          : isTikTokSpecialCampaign
          ? isFindJobs
            ? ROUTES.FIND_JOBS_TIKTOK_SPECIAL_ID
            : ROUTES.MY_JOBS_TIKTOK_SPECIAL_ID
          : isFindJobs
          ? ROUTES.FIND_JOBS_MARKETPLACE_ID
          : isWaiting
          ? ROUTES.MY_JOBS_WAITING_ID
          : ROUTES.MY_JOBS_MARKETPLACE_ID,
        { id }
      )}
    >
      <div>
        <div css={styles.imageContainer}>
          <img src={thumbnail} />
          <div>
            {socialMedias
              ?.filter(socialMedia => socialMedia !== CampaignSocialMediaType.OTHER)
              .map(socialMedia =>
                socialMedia === CampaignSocialMediaType.UNSELECT ? (
                  <div css={styles.anyMethodsIndicator} key={socialMedia}>
                    {t('Any Methods')}
                  </div>
                ) : (
                  <div css={styles.socialMediaIndicator} key={socialMedia}>
                    <Icomoon icon={switchImage({ socialMedia })} size={12} />
                  </div>
                )
              )}
          </div>
          {isOverBudget ? (
            <Tag color="#d4d8dc">
              <div>{t('Closed')}</div>
            </Tag>
          ) : isSelection ? (
            isPreLaunchSelection && isAutoInfluencerApproval ? (
              <Tag color="#ffb63d">
                {isEcommerceCampaign && <Icomoon color="#fff" icon="crown" size={10} />}
                <div>{t('Coming soon')}</div>
              </Tag>
            ) : (
              <Tag color="#7ec963">
                {isEcommerceCampaign && <Icomoon color="#fff" icon="crown" size={10} />}
                <div>{t('Selection')}</div>
              </Tag>
            )
          ) : isEcommerceCampaign ? (
            <Tag color="#43Bde3">
              <Icomoon color="#fff" icon="crown" size={10} />
              <div>{t('Special Deal')}</div>
            </Tag>
          ) : isNewCampaign ? (
            <Tag color="#e3436a">{t('New')}</Tag>
          ) : null}
          {isFinished && <div css={styles.finishedOverlay}>{t('Finished')}</div>}
        </div>

        <div css={styles.contentContainer}>
          <TextCutter css={(isGiftingCampaign || isRevenue) && { alignSelf: 'flex-start' }} text={title} lines={3} />
          {isGiftingCampaign ? (
            <div css={styles.gifting}>
              <Icomoon color="#27313b" icon="gift-outlined" size={16} />
              <div>{t('Gifting')}</div>
            </div>
          ) : isRevenue ? (
            <div>
              {revenues.map(({ isPercentage, title: revenueTitle, value }) => (
                <div key={revenueTitle}>
                  <div>{`${t(revenueTitle)}/`}</div>
                  <div
                    css={
                      ((isSocialMediaCampaign && !!minFollowers && !isMobileView) || isMobileView) && {
                        width: 'fill-available',
                      }
                    }
                  >
                    {isPercentage
                      ? formatPercent(value)
                      : `${currencySymbol}${formatNumberWithCommas(value, undefined, currency)}`}
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          {isSocialMediaCampaign && !!minFollowers && (
            <div css={{ display: 'grid', gap: isMobileView ? THEME.box.gaps.xs : THEME.box.gaps.s }}>
              {(isAnyMethodsCampaign ? socialMediaTypes : socialMedias).map(socialMedia => (
                <div css={styles.minFollowersContainer} key={socialMedia}>
                  <Icomoon icon={switchImage({ socialMedia })} size={10.5} />
                  <div>
                    {t('Followers over count', {
                      count: formatNumberWithCommas(minFollowers, 0) as never,
                    })}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {showStatus && <StatusIndicator status={status as AnyXMarketplaceCampaignStatus} />}
    </Link>
  );
};

const Tag = styled.div<{ color: string }>(({ color }) => ({
  alignItems: 'center',
  backgroundColor: color,
  borderRadius: '6px 0 6px',
  color: THEME.font.colors.white,
  display: 'flex',
  fontSize: THEME.font.sizes.subordinate,
  fontWeight: 600,
  gap: 6,
  padding: '6px 8px',
  position: 'absolute',
  top: 0,
}));

const styles = {
  anyMethodsIndicator: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 32,
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.hint,
    fontWeight: 600,
    padding: '4px 24px',
  }),
  card: css({
    backgroundColor: THEME.colors.white,
    borderRadius: 15,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.s,
    padding: 12,
    width: 'fill-available',

    '& > div:nth-of-type(1)': {
      display: 'flex',
      gap: THEME.box.gaps.m,
    },
  }),
  contentContainer: css({
    alignItems: 'center',
    display: 'grid',
    gap: THEME.box.gaps.s,
    padding: '6px 0',
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      gap: THEME.box.gaps.s,
    },

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: 13,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        fontSize: THEME.font.sizes.heading,
      },
    },

    '& > div:nth-of-type(2)': {
      alignSelf: 'flex-end',
      display: 'grid',
      gap: THEME.box.gaps.s,

      '& > div': {
        alignItems: 'center',
        display: 'flex',
        gap: THEME.box.gaps.s,
        justifyContent: 'flex-end',

        '& > div:nth-of-type(1)': {
          background: THEME.colors.gray.eef3f7,
          borderRadius: THEME.box.borderRadius.xs,
          color: THEME.font.colors.gray.main,
          fontSize: 11,
          fontWeight: 600,
          padding: 4,

          [`@media (min-width: ${ViewportType.TABLET}px)`]: {
            minWidth: 'max-content',
            fontSize: THEME.font.sizes.subordinate,
          },
        },

        '& > div:nth-of-type(2)': {
          color: THEME.font.colors.black.main,
          fontSize: THEME.font.sizes.subHeading,
          fontWeight: 600,
          textAlign: 'right',

          [`@media (min-width: ${ViewportType.TABLET}px)`]: {
            fontSize: THEME.font.sizes.heading,
            textAlign: 'unset',
          },
        },
      },

      '& > div:nth-of-type(n + 5)': {
        display: 'none',
      },
    },
  }),
  gifting: css({
    alignItems: 'center',
    display: 'flex',
    gap: THEME.box.gaps.xs,

    '& > div': {
      color: '#27313b !important',
      fontSize: '18px !important',
      fontWeight: '600 !important',
    },
  }),
  finishedOverlay: css({
    alignItems: 'center',
    background: THEME.colors.black.main,
    borderRadius: 10,
    bottom: 0,
    color: THEME.font.colors.white,
    display: 'flex',
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 600,
    justifyContent: 'center',
    left: 0,
    margin: 'auto',
    opacity: 0.8,
    position: 'absolute',
    right: 0,
    textTransform: 'uppercase',
    top: 0,
  }),
  imageContainer: css({
    backgroundColor: THEME.colors.gray.f6f8fa,
    borderRadius: 6,
    height: 88,
    position: 'relative',
    width: 156,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      height: 144,
      width: 256,
    },

    '& > img': {
      borderRadius: 6,
      height: 88,
      objectFit: 'contain',
      width: 156,

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        height: 144,
        width: 256,
      },
    },

    '& > div:nth-of-type(1)': {
      bottom: 6,
      display: 'flex',
      flexWrap: 'wrap',
      gap: THEME.box.gaps.xs,
      left: 6,
      position: 'absolute',
    },
  }),
  minFollowersContainer: css({
    alignItems: 'center',
    color: THEME.font.colors.gray.main,
    display: 'flex',
    fontSize: 11,
    gap: THEME.box.gaps.s,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.subordinate,
    },
  }),
  socialMediaIndicator: css({
    alignItems: 'center',
    backgroundColor: THEME.colors.white,
    borderRadius: '50%',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    width: 20,
  }),
};

export default Card;
