import React from 'react';
import { TooltipFormatterContextObject } from 'highcharts';
import { renderToStaticMarkup } from 'react-dom/server';
import { TFunction } from 'i18next';
import { ChartColorTypes } from '@src/components/organisms/AnalyticsFacebookPage/Overview/helpers';
import { TooltipDataType } from '../Overview/helpers';
import { IncrementSection, styles } from './Shared';

interface TooltipTemplate {
  tooltipData: TooltipDataType<ChartColorTypes>;
  key: TooltipFormatterContextObject['key'];
  t: TFunction;
  dir: 'rtl' | 'ltr';
}
export const TooltipTemplateFbDashboard = ({ tooltipData, key, t, dir }: TooltipTemplate) => {
  if (!key) {
    return '';
  }

  const followersIncrement = tooltipData[key].Follower.increment;

  return renderToStaticMarkup(
    <div dir={dir} css={styles.tooltip}>
      <h3 css={styles.heading}>{key}</h3>

      <div css={styles.grid}>
        {tooltipDataset.map((rowItem, i) => (
          <div css={[styles.rowItem]} key={`${rowItem.dataKey}_${i}`}>
            <div css={styles.verticalLine} />
            <div css={styles.numbersWrapper}>
              <span css={styles.rowTitle}>{t(rowItem.label) as any}</span>
              <p css={[styles.rowData]}>
                <span>{tooltipData[key][rowItem.dataKey]?.value || '-'}</span>
                {rowItem.hasIncrement ? <IncrementSection followersIncrement={followersIncrement} /> : null}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const tooltipDataset: {
  label: string;
  dataKey: keyof TooltipDataType<ChartColorTypes>['key'];
  hasIncrement: boolean;
}[] = [
  { label: 'Follower', dataKey: 'Follower', hasIncrement: true },
  { label: 'Post', dataKey: 'Post', hasIncrement: false },
  { label: 'View', dataKey: 'View', hasIncrement: false },
  { label: 'Reaction', dataKey: 'Reaction', hasIncrement: false },
  { label: 'Comment', dataKey: 'Comment', hasIncrement: false },
  { label: 'Share', dataKey: 'Share', hasIncrement: false },
  { label: 'Impression', dataKey: 'Impression', hasIncrement: false },
  { label: 'Engagement', dataKey: 'Engagement', hasIncrement: false },
  { label: 'Engagement Rate', dataKey: 'Engagement', hasIncrement: false },
  { label: 'PageCta', dataKey: 'PageCta', hasIncrement: false },
  { label: 'Like', dataKey: 'Page Likes', hasIncrement: false },
  { label: 'Page Impression', dataKey: 'Page Impression', hasIncrement: false },
];
