import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import yourTasks from '@src/assets/img/myJobs/yourTasksBackground.png';
import { AutoLink, Icomoon, Image, VideoPreview } from '@src/components/atoms';
import { usePayableCampaignQuery } from '@src/graphql/hooks';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { isVideoThumbnail } from '@src/libs/image';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { MarketplaceCampaignDraftPostStatus } from '@src/__generated__/globalTypes';

interface StatusIndicatorProps {
  status?: MarketplaceCampaignDraftPostStatus;
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
  const { t } = useQueryHelper();

  const { background, color, icon, title } = (() => {
    switch (status) {
      case MarketplaceCampaignDraftPostStatus.APPROVED:
        return { background: '#c6ead8', color: '#40b87c', icon: 'check-circle-filled', title: 'Approved' };
      case MarketplaceCampaignDraftPostStatus.REJECTED:
        return { background: '#ffcfcf', color: THEME.font.colors.error, icon: 'info-circle', title: 'Need modify' };
      default:
        return { background: '#ebc6f8', color: '#9847b5', icon: 'clock-outlined', title: 'Reviewing' };
    }
  })();

  return (
    <Badge background={background} color={color}>
      <Icomoon color={color} icon={icon} size={14} />
      <div>{t(title)}</div>
    </Badge>
  );
};

enum Status {
  GET_APPROVAL = 'GET_APPROVAL',
  GET_REVENUE = 'GET_REVENUE',
  JOIN_CAMPAIGN = 'JOIN_CAMPAIGN',
  MAKE_POST = 'MAKE_POST',
  SUBMIT_DRAFT_POST = 'SUBMIT_DRAFT_POST',
}

interface YourTasksProps {
  draftPosts: {
    content: string;
    materials: string[];
    rejectedMessage?: string | null;
    status: MarketplaceCampaignDraftPostStatus;
  }[];
}

const YourTasks = ({ draftPosts }: YourTasksProps) => {
  const { isMobileView } = usePageLayout();
  const { params, t } = useQueryHelper();
  const campaignId = Number(params.id);
  const tasks = [
    { title: 'Join campaign', value: Status.JOIN_CAMPAIGN },
    { title: 'Submit the draft post', value: Status.SUBMIT_DRAFT_POST },
    { title: 'Get approval from the brand', value: Status.GET_APPROVAL },
    { title: 'Make a campaign post on the social media', value: Status.MAKE_POST },
    { title: 'Get revenue', value: Status.GET_REVENUE },
  ];

  const { data: dataPayableCampaign } = usePayableCampaignQuery();
  const latestDraftPosts = draftPosts?.[draftPosts.length - 1];
  const status = (() => {
    if (!!dataPayableCampaign?.payableCampaign?.find(c => c.id === campaignId)) {
      return Status.GET_REVENUE;
    } else if (latestDraftPosts?.status === MarketplaceCampaignDraftPostStatus.APPROVED) {
      return Status.MAKE_POST;
    } else if (latestDraftPosts?.status === MarketplaceCampaignDraftPostStatus.DRAFT) {
      return Status.GET_APPROVAL;
    }

    return Status.SUBMIT_DRAFT_POST;
  })();
  const isRequiredSubmitDraftPostWithoutHistory =
    [Status.JOIN_CAMPAIGN, Status.SUBMIT_DRAFT_POST].includes(status) && !latestDraftPosts;

  return (
    <div css={styles.container}>
      {isRequiredSubmitDraftPostWithoutHistory && (
        <img
          alt="yourTasks"
          css={styles.image}
          height={isMobileView ? 168 : 285}
          src={yourTasks}
          width={isMobileView ? 224 : 380}
        />
      )}
      <div
        css={[
          styles.tasksContainer,
          ...(!isRequiredSubmitDraftPostWithoutHistory ? [{ width: 'fill-available !important' }] : []),
        ]}
      >
        {tasks.map(({ title, value }, index) => (
          <Task isActive={value === status} key={value}>
            <div>{!index ? <>&#x2713;</> : index + 1}</div>
            <div>{t(title)}</div>
          </Task>
        ))}
      </div>

      {!isRequiredSubmitDraftPostWithoutHistory && (
        <>
          <div css={styles.postStatusContainer}>
            <div css={styles.postStatusHeader}>
              <div>{t('Post Status')}</div>
              <StatusIndicator status={latestDraftPosts?.status} />
            </div>
            {latestDraftPosts?.status === MarketplaceCampaignDraftPostStatus.REJECTED && (
              <div css={styles.postStatusWarning}>
                <Icomoon color="#ff5f5f" icon="warning-circle-filled" size={16} />
                <div>{t('Modify your post according to the feedback')}</div>
              </div>
            )}
            <div css={{ display: 'flex', flexWrap: 'wrap', gap: THEME.box.gaps.s }}>
              {latestDraftPosts?.materials.map((material, index) =>
                isVideoThumbnail(material) ? (
                  <VideoPreview
                    css={{ borderRadius: 12 }}
                    height={isMobileView ? 80 : 96}
                    key={index}
                    url={material}
                    width={isMobileView ? 80 : 96}
                  />
                ) : (
                  <Image
                    css={{ borderRadius: 12 }}
                    height={isMobileView ? 80 : 96}
                    key={index}
                    src={material}
                    width={isMobileView ? 80 : 96}
                  />
                )
              )}
            </div>
            <div css={styles.postStatusContent}>{latestDraftPosts?.content}</div>
            {latestDraftPosts?.status === MarketplaceCampaignDraftPostStatus.REJECTED &&
              latestDraftPosts?.rejectedMessage && (
                <div css={styles.postStatusWarningMessage}>
                  <div>
                    <div>
                      <Icomoon color="#ff5f5f" icon="warning-circle-filled" size={14} />
                      <div>{t('Modify Feedback')}</div>
                    </div>
                    <div>{t(`Annotation.Your draft post wasn't approved, please check below`)}</div>
                  </div>
                  <AutoLink rel="noopener noreferrer" target="_blank" text={latestDraftPosts.rejectedMessage} />
                </div>
              )}
          </div>

          {!isMobileView && latestDraftPosts?.status === MarketplaceCampaignDraftPostStatus.DRAFT && (
            <div css={styles.hint}>
              {t('You will be notified via email and notification tab in AnyCreator app when you get a feedback')}
            </div>
          )}

          <div css={{ display: 'grid', gap: isMobileView ? 16 : 24 }}>
            <div css={{ color: THEME.font.colors.black.main, fontSize: isMobileView ? 14 : 20, fontWeight: 600 }}>
              {t('Draft History')}
            </div>
            <div css={styles.historyDraftPostContainer}>
              {draftPosts
                ?.slice(0, -1)
                .reverse()
                .map(({ content, materials, rejectedMessage }, index) => (
                  <div key={index}>
                    <div css={{ display: 'flex', flexWrap: 'wrap' }}>
                      {materials.map((material, mIndex) =>
                        isVideoThumbnail(material) ? (
                          <VideoPreview
                            css={{ borderRadius: 10 }}
                            height={isMobileView ? 80 : 96}
                            key={mIndex}
                            url={material}
                            width={isMobileView ? 80 : 96}
                          />
                        ) : (
                          <Image
                            css={{ borderRadius: 10 }}
                            height={isMobileView ? 80 : 96}
                            key={mIndex}
                            src={material}
                            width={isMobileView ? 80 : 96}
                          />
                        )
                      )}
                    </div>
                    <div>{content}</div>
                    {rejectedMessage && (
                      <div>
                        <div>
                          <div>{t('Modify Feedback')}</div>
                          <div>{t(`Annotation.Your draft post wasn't approved, please check below`)}</div>
                        </div>
                        <AutoLink rel="noopener noreferrer" target="_blank" text={rejectedMessage} />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Badge = styled.div<{ background: string; color: string }>(({ background, color }) => ({
  alignItems: 'center',
  background,
  borderRadius: 35,
  color,
  display: 'flex',
  fontSize: THEME.font.sizes.subordinate,
  gap: THEME.box.gaps.xs,
  minWidth: 'fit-content',
  padding: '4px 8px',
}));

const Task = styled.div<{ isActive: boolean }>(({ isActive }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: THEME.box.gaps.s,

  [`@media (min-width: ${ViewportType.TABLET}px)`]: {
    gap: THEME.box.gaps.m,
  },

  '& > div:nth-of-type(1)': {
    alignItems: 'center',
    background: isActive ? '#ec4d74' : '#27313b',
    borderRadius: '50%',
    color: THEME.font.colors.white,
    display: 'flex',
    fontSize: 9,
    fontWeight: 600,
    height: 16,
    justifyContent: 'center',
    width: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.subHeading,
      height: 28,
      width: 28,
    },
  },

  '& > div:nth-of-type(2)': {
    color: isActive ? '#ec4d74' : '#27313b',
    fontSize: THEME.font.sizes.normal,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.subTitle,
    },
  },
}));

const styles = {
  container: css({
    display: 'grid',
    padding: '64px 16px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '48px 0',
    },
  }),
  hint: css({
    color: THEME.font.colors.blue.main,
    fontSize: THEME.font.sizes.subHeading,
    marginBottom: 24,
  }),
  historyDraftPostContainer: css({
    display: 'grid',
    gap: THEME.box.gaps.l,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      gap: THEME.box.gaps.xxl,
    },

    '& > div': {
      borderBottom: '1px solid #dee5ec',
      display: 'grid',
      gap: THEME.box.gaps.m,
      paddingBottom: 12,

      '& > div:nth-of-type(1)': {
        display: 'flex',
        gap: THEME.box.gaps.s,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.normal,

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.subHeading,
        },
      },

      '& > div:nth-of-type(3)': {
        color: THEME.font.colors.gray.main,
        display: 'grid',
        fontSize: THEME.font.sizes.subordinate,
        gap: THEME.box.gaps.m,

        [`@media (min-width: ${ViewportType.TABLET}px)`]: {
          fontSize: THEME.font.sizes.normal,
        },

        '& > div:nth-of-type(1)': {
          '& > div:nth-of-type(1)': {
            fontWeight: 600,
          },
        },

        '& > div:nth-of-type(2)': {
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
        },
      },
    },

    '& > div:last-child': {
      border: 'none',
      paddingBottom: 0,
    },
  }),
  image: css({
    justifySelf: 'center',
    marginBottom: 48,
  }),
  postStatusContainer: css({
    background: THEME.colors.white,
    borderRadius: 15,
    display: 'grid',
    gap: THEME.box.gaps.l,
    marginBottom: 24,
    padding: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '16px 24px',
    },
  }),
  postStatusContent: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subHeading,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  }),
  postStatusHeader: css({
    alignItems: 'center',
    display: 'flex',

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      fontWeight: 600,
      width: 'fill-available',

      [`@media (min-width: ${ViewportType.TABLET}px)`]: {
        fontSize: THEME.font.sizes.subTitle,
      },
    },
  }),
  postStatusWarning: css({
    alignItems: 'center',
    color: THEME.font.colors.error,
    display: 'flex',
    fontSize: THEME.font.sizes.normal,
    fontWeight: 600,
    gap: THEME.box.gaps.xs,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      fontSize: THEME.font.sizes.subHeading,
    },
  }),
  postStatusWarningMessage: css({
    background: '#f6f8fa',
    borderRadius: 8,
    display: 'grid',
    gap: THEME.box.gaps.m,
    padding: '6px 12px',
    maxWidth: 'fill-available',

    '& > div': {
      fontSize: THEME.font.sizes.normal,

      '& > div:nth-of-type(1)': {
        alignItems: 'center',
        color: THEME.font.colors.error,
        display: 'flex',
        fontWeight: 600,
        gap: THEME.box.gaps.xs,
      },

      '& > div:nth-of-type(2)': {
        color: THEME.font.colors.black.main,
      },
    },

    '& > p': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
    },
  }),
  tasksContainer: css({
    background: THEME.colors.white,
    borderRadius: 25,
    boxShadow: THEME.box.shadows.outer,
    display: 'grid',
    gap: THEME.box.gaps.s,
    justifySelf: 'center',
    marginBottom: 16,
    padding: 16,
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      gap: 14,
      marginBottom: 24,
      padding: 24,
      width: 571,
    },
  }),
};

export default YourTasks;
