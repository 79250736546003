import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  JoinTiktokSpecialCampaignMutation,
  JoinTiktokSpecialCampaignMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/JoinTiktokSpecialCampaign.graphql';

export const useJoinTiktokSpecialCampaignMutation = (
  options?: MutationHookOptions<JoinTiktokSpecialCampaignMutation, JoinTiktokSpecialCampaignMutationVariables>
) => useMutation<JoinTiktokSpecialCampaignMutation, JoinTiktokSpecialCampaignMutationVariables>(MUTATION, options);
