import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HighChart, { Highcharts } from '@src/components/atoms/HighChart';
import { formatPercent } from '@src/libs/format';
import DataWrapper, { SectionHeader } from '@src/components/molecules/DataCard/DataOpacityWrapper';
import { MoreButton } from '@src/components/molecules';
import Dialog, { smallButtonStyles } from '@src/components/molecules/Dialog';
import { Select } from '@src/components/shared';
import { useTranslateCountry } from '@src/libs/hooks/useTranslateOptions';
import { ALL_COUNTRIES_WITH_CODE, LIMIT_25 } from '@src/libs/constant';
import { css } from '@emotion/react';
import { useDirLayout } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { TooltipTemplateSmall } from '../TooltipTemplates';
import { FollowerRegionRate, FollowerCountryRate } from './types';

export interface AreaBreakdownProps {
  followerRegionRate: FollowerRegionRate[];
  followerCountryRate: FollowerCountryRate[];
  className?: string;
  hasCountriesRates?: boolean;
  hideSelector?: boolean;
}
type ChartType = 'regions' | 'countries';

const AreaBreakdown = ({
  followerRegionRate,
  followerCountryRate,
  hasCountriesRates,
  hideSelector,
  className,
}: AreaBreakdownProps) => {
  const { t } = useTranslation();
  const { tc } = useTranslateCountry();
  const [chartType, setChartType] = useState<ChartType>('regions');
  const { dir, isRtl } = useDirLayout();

  const { regionsRates, regions } = followerRegionRate?.reduce<{ regionsRates: number[]; regions: string[] }>(
    (acc, curr) => ({ regionsRates: [...acc.regionsRates, curr.rate], regions: [...acc.regions, t(curr.name)] }),
    { regionsRates: [], regions: [] }
  );
  const { countriesRates, countries } = followerCountryRate?.reduce<{ countriesRates: number[]; countries: string[] }>(
    (acc, curr) => ({ countriesRates: [...acc.countriesRates, curr.rate], countries: [...acc.countries, t(curr.id)] }),
    { countriesRates: [], countries: [] }
  );

  const [regionPopupOpen, setRegionPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    if (hasCountriesRates && !followerRegionRate.length) {
      setChartType('countries');
    }
  }, []);

  const countriesNamesFromCode = countries.map(c =>
    tc(ALL_COUNTRIES_WITH_CODE.find(el => el.code === c)?.name || '')
  ) as string[];
  const categories = chartType === 'regions' ? regions : countriesNamesFromCode;
  const seriesData = chartType === 'regions' ? regionsRates : countriesRates;
  const dataNotAvailable = regionsRates.every(rate => rate === 0) && countriesRates.every(rate => rate === 0);

  const chartOptions = (showAll?: boolean): Highcharts.Options => ({
    chart: {
      type: 'bar',
      height: showAll ? null : 265,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: showAll ? categories : categories.slice(0, 7),
      title: {
        text: null,
      },
      opposite: isRtl,
    },
    yAxis: {
      min: 0,
      title: {
        text: '',
      },
      reversed: isRtl,
      labels: {
        overflow: 'justify',
        formatter() {
          return formatPercent(+this.value, true, 0);
        },
      },
    },
    tooltip: {
      formatter() {
        return TooltipTemplateSmall({
          point: this.point,
          name: this.x?.toString() || '',
          dir,
        });
      },
      padding: 0,
      borderColor: 'green',
      borderWidth: 0,
      borderRadius: 3,
      backgroundColor: THEME.colors.black.main,
      style: {
        color: '#ffffff',
      },
      useHTML: true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
      },
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        // The dialog chart only can accommodate up to 25 data points
        // Beyond this limit, the data will begin to overlap
        data: showAll ? seriesData.slice(0, LIMIT_25) : seriesData.slice(0, 7),
        color: '#9ea0e0',
        type: 'bar',
      },
    ],
    legend: {
      symbolHeight: 15,
      symbolWidth: 15,
      symbolRadius: 2,
      itemStyle: {
        fontSize: '13px',
        color: THEME.font.colors.black.main,
        fontWeight: 'normal',
      },
      rtl: isRtl,
    },
  });
  const options = [
    ...(followerRegionRate.length ? [{ value: 'regions', label: t('Selector.Region') }] : []),
    ...(hasCountriesRates ? [{ value: 'countries', label: t('Select.Country') }] : []),
  ];
  const HeaderWithDropdown = (
    <div css={styles.headerWrapper}>
      <SectionHeader>{t('Area Breakdown')}</SectionHeader>
      {hasCountriesRates && !hideSelector && !dataNotAvailable ? (
        <Select
          css={{ width: 130 }}
          disableClear
          disableSearch
          options={options}
          value={chartType}
          onChange={el => setChartType(el as ChartType)}
        />
      ) : null}
    </div>
  );

  return (
    <DataWrapper header={HeaderWithDropdown} dataNotAvailable={dataNotAvailable} className={className}>
      <HighChart options={chartOptions()} />
      <MoreButton css={styles.moreBtn} onClick={() => setRegionPopupOpen(true)} />
      <Dialog
        cancelButtonProps={{
          styles: [smallButtonStyles, css({ width: 'fill-available !important' })],
          text: 'Close',
          onClick: () => setRegionPopupOpen(false),
        }}
        open={regionPopupOpen}
        onClose={() => setRegionPopupOpen(false)}
      >
        <div css={{ margin: 12 }}>{HeaderWithDropdown}</div>
        <HighChart options={chartOptions(true)} />
      </Dialog>
    </DataWrapper>
  );
};

const styles = {
  headerWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  moreBtn: {
    position: 'absolute' as any,
    bottom: 10,
    right: 10,

    '[dir="rtl"] &': {
      right: 'unset',
      left: 10,
    },
  },
};

export default AreaBreakdown;
