import React from 'react';
import IcoMoon, { iconList, IconProps } from 'react-icomoon';
import selection from '@src/assets/icomoon/selection.json';
import selectionColors from '@src/assets/icomoon/selection-colors.json';
import { THEME } from '@src/libs/theme';

const Icomoon = ({ color = THEME.colors.gray.main, icon, size = 14, ...restProps }: IconProps) => {
  // to combine selection icons but using the same configuration
  const iconSet = { ...selection, icons: [...selection.icons, ...selectionColors.icons] };
  const removeStroke = ['instagram-color', 'instagram-color-light', 'instagram-story-color'].includes(icon);
  const isSizeWithNumber = typeof size === 'number';
  const customSize = isSizeWithNumber ? `${size}px !important` : `${size} !important`;

  return (
    <IcoMoon
      css={[{ minHeight: customSize, minWidth: customSize }, removeStroke && { stroke: 'transparent !important' }]}
      color={color}
      icon={icon}
      iconSet={iconSet}
      size={size}
      {...restProps}
    />
  );
};

export { iconList, selection, selectionColors };
export default Icomoon;
