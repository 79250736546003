import axios from 'axios';
import React, { useEffect, useState, ReactNode } from 'react';
import Dropzone from 'react-dropzone';
import { useApolloClient } from '@apollo/client';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CircularProgress, Icomoon } from '@src/components/atoms';
import QUERY from '@src/graphql/queries/GeneratePostFileSignedUrls.graphql';
import { getToken } from '@src/libs/auth';
import { useQueryHelper } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import {
  GeneratePostFileSignedUrlsQuery,
  GeneratePostFileSignedUrlsQueryVariables,
  SignedUrlData,
} from '@src/__generated__/globalTypes';
import { DEFAULT_UPLOAD_LIMIT, VIDEO_UPLOAD_LIMIT } from './helpers';

interface DropContentProps {
  disabled?: boolean;
  error?: boolean;
  isCustomContent?: boolean;
}

interface DragAndDropProps extends DropContentProps {
  accept?: string;
  className?: string;
  contentNotes?: string[];
  contentUploadLabel?: string;
  customCancelButton?: ReactNode;
  customContent?: ReactNode;
  hideLoading?: boolean;
  isRequestAbortable?: boolean;
  multiple?: boolean;
  uploadUrl?: string;
  value?: string[];
  generatePostFileSignedUrl?: (files: string[]) => Promise<SignedUrlData[]>;
  onChange?: (value: string[]) => void;
  onDrop?: (value: File[]) => void;
  onProgress?: (isUploading: boolean) => void;
}

const DragAndDrop = ({
  accept,
  className,
  contentNotes = [],
  customCancelButton,
  customContent,
  contentUploadLabel,
  disabled,
  error,
  hideLoading,
  isRequestAbortable,
  multiple,
  uploadUrl,
  value = [],
  generatePostFileSignedUrl,
  onChange,
  onDrop,
  onProgress,
}: DragAndDropProps) => {
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const client = useApolloClient();
  const { enqueueSnackbar, t } = useQueryHelper();

  useEffect(() => {
    if (!!onProgress) {
      onProgress(isUploading);
    }
  }, [isUploading]);

  const defaultGeneratePostFileSignedUrl = async (fileNames: string[]) => {
    const { data } = await client.query<GeneratePostFileSignedUrlsQuery, GeneratePostFileSignedUrlsQueryVariables>({
      query: QUERY,
      variables: { fileNames },
    });

    return data?.generatePostFileSignedUrls?.fileUploadUrls;
  };

  const onClickAbortRequest = () => {
    abortController.abort(new Error('Request canceled by user'));
    setAbortController(new AbortController());
  };

  const onDropUpload = async (files: File[]) => {
    if (!files.length) {
      return;
    }

    const isExceedSizeLimit = files.some(file => {
      const isVideoFile = ['video/avi', 'video/mp4', 'video/quicktime'].includes(file.type);
      const sizeLimit = isVideoFile ? VIDEO_UPLOAD_LIMIT : DEFAULT_UPLOAD_LIMIT;

      return file.size > sizeLimit;
    });

    if (isExceedSizeLimit) {
      enqueueSnackbar(t('Exceed file size limit'), { variant: 'error' });

      return;
    }

    setIsUploading(true);
    let uploadedFiles = [...value];
    const genPostFileSignedUrl = generatePostFileSignedUrl
      ? generatePostFileSignedUrl
      : defaultGeneratePostFileSignedUrl;
    const postFiles = uploadUrl ? undefined : await genPostFileSignedUrl(files.map(file => file.name));
    if (postFiles) {
      try {
        for (const [index, postFile] of postFiles.entries()) {
          const file = files[index];
          const url = postFile.signedUrl.split('?')[0];

          await axios.put(postFile.signedUrl, file, { signal: abortController.signal });
          if (multiple) {
            uploadedFiles = [...uploadedFiles, url];
          } else {
            uploadedFiles = [url];
          }
        }
        onChange?.(uploadedFiles);
      } catch (err) {
        enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
      }
    } else if (uploadUrl) {
      const file = files[0];
      const formData = new FormData();
      formData.append(file.name, file);
      try {
        await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
            authorization: getToken(),
          },
          signal: abortController.signal,
        });
        onChange?.([uploadUrl]);
      } catch (err) {
        enqueueSnackbar(t('fileUploadFail'), { variant: 'error' });
      }
    }
    setIsUploading(false);
  };

  return (
    <Dropzone
      accept={accept}
      disabled={disabled || isUploading}
      multiple={uploadUrl ? false : !!multiple}
      onDrop={onDrop || onDropUpload}
    >
      {({ getInputProps, getRootProps }) => (
        <DropContent
          {...getRootProps()}
          className={className}
          disabled={disabled || isUploading}
          error={error}
          isCustomContent={!!customContent}
        >
          <input {...getInputProps()} />
          {isUploading && !hideLoading && (
            <div css={styles.overlay}>
              {isRequestAbortable ? (
                customCancelButton ? (
                  customCancelButton
                ) : (
                  <div className="cancel-btn" onClick={onClickAbortRequest}>
                    <Icomoon color="#6e7c89" icon="clear" size={6} />
                  </div>
                )
              ) : null}
              <CircularProgress size="32px" thickness="4px" />
            </div>
          )}
          {customContent ? (
            customContent
          ) : (
            <>
              <Icomoon color="#dfe8ed" icon="cloud-upload" size={56} />
              {!!contentNotes.length && (
                <div className="drop-content-notes">
                  {contentNotes.map((note, index) => (
                    <div key={index}>{note}</div>
                  ))}
                </div>
              )}
              <Label disabled={!!disabled}>{t(contentUploadLabel || 'Button.Browse to Upload')}</Label>
            </>
          )}
        </DropContent>
      )}
    </Dropzone>
  );
};

const DropContent = styled.div<DropContentProps>(({ disabled, error, isCustomContent }) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',
  position: 'relative',
  ...(!isCustomContent
    ? {
        border: '1px dashed #e0e8ed',
        borderColor: error ? 'tomato' : '#e0e8ed',
        borderRadius: 3,
        boxSizing: 'border-box',
        display: 'grid',
        gap: THEME.box.gaps.s,
        justifyItems: 'center',
        transition: 'border-color 0.3s ease-in-out',
        padding: 16,
        width: '100%',

        '&:hover': {
          borderColor: disabled ? '#e0e8ed' : '#179cd7',
        },

        '& > .drop-content-notes': {
          display: 'grid',
          justifyContent: 'center',
          width: '100%',

          '& > div': {
            color: THEME.font.colors.gray.main,
            fontSize: 11,
            width: '100%',
          },
        },
      }
    : {
        '& > :last-child': {
          borderColor: error ? 'tomato' : '#e0e8ed',
          transition: 'border-color 0.3s ease-in-out',

          '&:hover': {
            borderColor: disabled ? '#e0e8ed' : error ? 'tomato' : '#179cd7',
          },
        },
      }),
}));

const Label = styled.div<{ disabled: boolean }>(({ disabled }) => ({
  color: disabled ? '#6e7c89' : '#3892e5',
  fontSize: 13,
  fontWeight: 500,

  [`${DropContent}:hover &`]: {
    textDecoration: disabled ? 'none' : 'underline',
  },
}));

const styles = {
  overlay: css({
    alignContent: 'center',
    background: 'rgb(246, 248, 250, 0.3)',
    cursor: 'default',
    display: 'grid',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,

    '& > .cancel-btn': {
      alignItems: 'center',
      background: THEME.colors.white,
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'grid',
      height: 12,
      justifyContent: 'center',
      position: 'absolute',
      right: 8,
      top: 8,
      width: 12,
    },
  }),
};

export default DragAndDrop;
