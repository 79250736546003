import { format } from 'date-fns';
import { ReactNode } from 'react';
import { DEFAULT_FNS_DATE_FORMAT, LIMIT_10 } from './constant';
import { Material } from './requests';

export interface Option {
  label: string;
  value: string;
  icon?: ReactNode;
  prefixIcon?: ReactNode;
}

export interface BankOption extends Option {
  hasBranches: boolean;
}

export const getOptions = <T extends { id: number | string; name: string }>(
  items?: Array<T | null> | null
): Option[] => {
  if (!items) {
    return [];
  }

  return items
    .map(item =>
      item
        ? {
            value: String(item.id),
            label: item.name,
          }
        : null
    )
    .filter((item): item is Option => item !== null);
};

export const getBankOptions = <T extends { id: number | string; name: string; hasBranches: boolean }>(
  items?: Array<T | null> | null
): BankOption[] => {
  if (!items) {
    return [];
  }

  return items
    .map(item =>
      item
        ? {
            value: String(item.id),
            label: item.name,
            hasBranches: item.hasBranches,
          }
        : null
    )
    .filter((item): item is BankOption => item !== null);
};

export const getInitialMaterials = <
  T extends {
    id: number;
    url: string;
  }
>(
  payload: Array<T | null> | null
) => {
  if (!payload) {
    return [] as Material[];
  }

  // Filter out nulls from materials
  const materials = payload.reduce<Material[]>((previous, current) => {
    if (!!current) {
      const { id, url } = current;

      return [...previous, { id, url }];
    }

    return previous;
  }, []);

  return materials;
};

export const getFirstDayOfTheMonth = () => {
  const date = new Date();

  return format(new Date(date.getFullYear(), date.getMonth(), 1), DEFAULT_FNS_DATE_FORMAT);
};

export const sliceItemsOnPage = (items: any[], page: number, limit: number = LIMIT_10) =>
  items.slice((page - 1) * limit, limit * page);
