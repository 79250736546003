import LinkifyIt from 'linkify-it';
import React, { Fragment } from 'react';
import tlds from 'tlds';
import styled from '@emotion/styled';
import { THEME } from '@src/libs/theme';

interface AutoLinkProps {
  rel?: string;
  target?: string;
  text: string;
}

const AutoLink = ({ rel, target, text }: AutoLinkProps) => {
  const linkify = new LinkifyIt();
  linkify.tlds(tlds);

  const matches = linkify.match(text);

  if (!matches) {
    return <Paragraph>{text}</Paragraph>;
  }

  return (
    <Paragraph>
      {matches.map((match, index) => {
        const previousLastIndex = !!index ? matches[index - 1].lastIndex : 0;
        const previousText = !!match.index && text.slice(previousLastIndex, match.index);
        const remainingText = index === matches.length - 1 && text.slice(match.lastIndex, text.length);

        return (
          <Fragment key={index}>
            {previousText}
            <a href={match.url} rel={rel} target={target}>
              {match.text}
            </a>
            {remainingText}
          </Fragment>
        );
      })}
    </Paragraph>
  );
};

const Paragraph = styled.p({
  wordBreak: 'break-word',

  '& a': {
    color: THEME.font.colors.blue.main,
    display: 'inline',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default AutoLink;
