import { TooltipFormatterContextObject } from 'highcharts';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { TFunction } from 'i18next';
import { InteractionChartColorTypes } from '@src/components/organisms/AnalyticsInstagram/Interaction/Overview/interactionHelpers';
import { TooltipDataType } from '../Overview/helpers';
import { IncrementSection, styles } from './Shared';

interface TooltipTemplate {
  tooltipData: TooltipDataType<InteractionChartColorTypes>;
  key: TooltipFormatterContextObject['key'];
  t: TFunction;
  dir: 'rtl' | 'ltr';
}

export const TooltipTemplateGridIgInteraction = ({ tooltipData, key, t, dir }: TooltipTemplate) => {
  if (!key) {
    return '';
  }

  const followersIncrement = tooltipData[key].Follower.increment;

  return renderToStaticMarkup(
    <div dir={dir} css={styles.tooltip}>
      <h3 css={styles.heading}>{key}</h3>

      <div
        css={[
          styles.grid,
          {
            gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
            gridTemplateAreas: `"area0 area0 area0" "area1 area2 area3" "area4 area5 area6"`,
          },
        ]}
      >
        {tooltipDataset.map((rowItem, i) => (
          <div css={[styles.rowItem, { gridArea: `area` + i }]} key={`${rowItem.dataKey}_${i}`}>
            <div css={styles.verticalLine} />
            <div css={styles.numbersWrapper}>
              <span css={styles.rowTitle}>{t(rowItem.label) as any}</span>
              <p css={[styles.rowData]}>
                <span>{tooltipData[key][rowItem.dataKey]?.value || '-'}</span>
                {rowItem.hasIncrement ? <IncrementSection followersIncrement={followersIncrement} /> : null}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const tooltipDataset: {
  label: string;
  dataKey: keyof TooltipDataType<InteractionChartColorTypes>['key'];
  hasIncrement: boolean;
}[] = [
  { label: 'Follower', dataKey: 'Follower', hasIncrement: true },
  { label: 'Tagged Posts', dataKey: 'Tagged Posts', hasIncrement: false },
  { label: 'Tagged Likes', dataKey: 'Tagged Likes', hasIncrement: false },
  { label: 'Tagged Comments', dataKey: 'Tagged Comments', hasIncrement: false },
  { label: 'Mentioned Posts', dataKey: 'Mentioned Posts', hasIncrement: false },
  { label: 'Mentioned Likes', dataKey: 'Mentioned Likes', hasIncrement: false },
  { label: 'Mentioned Comments', dataKey: 'Mentioned Comments', hasIncrement: false },
];
