import { css } from '@emotion/react';
import { Point } from 'highcharts';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { formatPercent } from '@src/libs/format';
import { THEME } from '@src/libs/theme';

interface TooltipTemplate {
  point: Point;
  name: string | number;
  dir: 'rtl' | 'ltr';
  seriaName?: string | number;
  isPercentage?: boolean;
}
export const TooltipTemplateSmall = ({ seriaName, point, name, dir, isPercentage = true }: TooltipTemplate) =>
  renderToStaticMarkup(
    <div css={styles.tooltip} dir={dir}>
      {seriaName ? <p>{seriaName}</p> : null}
      <div css={[styles.item, { gap: '16px' }]}>
        <div css={styles.item}>
          <span css={[styles.icon, { backgroundColor: point.color as string }]} />
          <span>{name}:</span>
        </div>
        <span>{isPercentage ? formatPercent(point.y) : point.y}</span>
      </div>
    </div>
  );

const styles = {
  tooltip: css({
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: THEME.box.gaps.s,
    padding: '13px 16px',
  }),
  item: css({
    display: 'flex',
    alignItems: 'center',
    gap: THEME.box.gaps.s,
  }),
  icon: css({
    display: 'inline-block',
    height: 12,
    width: 12,
  }),
};
