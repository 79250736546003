import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { css } from '@emotion/react';
import serverErrorImg from '@src/assets/img/youtubeCmsServerError.png';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';

interface YoutubeCmsServerErrorProps {
  pageTitle: string;
}

const YoutubeCmsServerError = ({ pageTitle }: YoutubeCmsServerErrorProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.container}>
      <img className="image" src={serverErrorImg} />
      <label className="title">{t('server error')}</label>
      <label className="description">
        <Trans
          i18nKey="Annotation.We're sorry, but due to a server issue, the title page is temporarily unavailable"
          values={{ title: t(`Title.${pageTitle}`) }}
        />
      </label>
    </div>
  );
};

const styles = {
  container: css({
    textAlign: 'center',
    width: 295,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      width: 324,
    },

    '& > .image': {
      height: 164,
      marginBottom: 16,
      width: '100%',
    },

    '& > .title': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.subTitle,
      fontWeight: 700,
      marginBottom: 8,
      textTransform: 'capitalize',
    },

    '& > .description': {
      color: THEME.font.colors.gray.main,
      fontSize: THEME.font.sizes.normal,
      padding: '0 8px',
    },
  }),
};

export default YoutubeCmsServerError;
