import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { TFunction } from 'i18next';
import { SocialAccountType } from '@src/__generated__/globalTypes';
import { styles } from './Shared';

interface TooltipTemplatePostingHabitsProps {
  point: any;
  t: TFunction;
  selectedSocialMedia?: SocialAccountType;
  dir: 'ltr' | 'rtl';
}
export const TooltipTemplatePostingHabits = ({
  point,
  t,
  selectedSocialMedia,
  dir,
}: TooltipTemplatePostingHabitsProps) =>
  renderToStaticMarkup(
    <div css={[styles.tooltip, { minWidth: '250px' }]} dir={dir}>
      <h1 css={styles.heading}>{point.time}</h1>

      <div css={{ display: 'flex', columnGap: '8px' }}>
        <div css={[styles.rowItem, { flex: 1 }]}>
          <div css={styles.verticalLine} />
          <div css={styles.numbersWrapper}>
            <p css={styles.rowTitle}>{t('General.Likes') as any}</p>
            <p css={styles.rowData}>{point.likes}</p>
          </div>
        </div>

        <div css={[styles.rowItem, { flex: 1 }]}>
          <div css={styles.verticalLine} />
          <div css={styles.numbersWrapper}>
            <p css={styles.rowTitle}>
              {
                (selectedSocialMedia && selectedSocialMedia === SocialAccountType.TWITTER
                  ? t('Label.Replies')
                  : t('Label.Comments')) as any
              }
            </p>
            <p css={styles.rowData}>{point.comments}</p>
          </div>
        </div>

        <div css={[styles.rowItem, { flex: 2 }]}>
          <div css={styles.verticalLine} />
          <div css={styles.numbersWrapper}>
            <p css={styles.rowTitle}>{t('General.Engagement Rate') as any}</p>
            <p css={styles.rowData}>{point.value}%</p>
          </div>
        </div>
      </div>
    </div>
  );
