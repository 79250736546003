import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  UpdateInfluencerPhoneNumberMutation,
  UpdateInfluencerPhoneNumberMutationVariables,
} from '@src/__generated__/globalTypes';
import MUTATION from '../mutations/UpdateInfluencerPhoneNumber.graphql';

export const useUpdateInfluencerPhoneNumberMutation = (
  options?: MutationHookOptions<UpdateInfluencerPhoneNumberMutation, UpdateInfluencerPhoneNumberMutationVariables>
) => useMutation<UpdateInfluencerPhoneNumberMutation, UpdateInfluencerPhoneNumberMutationVariables>(MUTATION, options);
