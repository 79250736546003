import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Icomoon, YoutubeCmsServerError } from '@src/components/atoms';
import { ListLoading, NotificationBadge, PagerV2 } from '@src/components/molecules';
import { getPageInfo } from '@src/components/molecules/Pager/helpers';
import { useYoutubeMusicCategoriesQuery, useYoutubeMusicListQuery } from '@src/graphql/hooks';
import { LIMIT_10 } from '@src/libs/constant';
import { ErrorTypes } from '@src/libs/error';
import { sliceItemsOnPage } from '@src/libs/functions';
import { usePageLayout, useQueryHelper, useUrl } from '@src/libs/hooks';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { dynamicTitleState, useRecoil } from '@src/recoilAtoms';
import { ROUTES } from '@src/shared/routes';
import MusicPlayer from '../MusicPlayer';

const CategoryId = () => {
  const [selectedMusicId, setSelectedMusicId] = useState<number | null>(null);
  const { isMobileView } = usePageLayout();
  const { params, t } = useQueryHelper();
  const { setRecoilState } = useRecoil(dynamicTitleState);
  const { searchParams } = useUrl();
  const categoryId = Number(params.categoryId);
  const page = Number(searchParams.get('p')) || 1;

  const { data, loading } = useYoutubeMusicCategoriesQuery({
    skip: !categoryId,
  });
  const {
    data: dataList,
    error: errorDataList,
    loading: loadingDataList,
  } = useYoutubeMusicListQuery({
    skip: !categoryId,
    variables: {
      categoryId,
    },
  });
  const categories = data?.youtubeMusicCategories || [];
  const isServerError = errorDataList?.message === ErrorTypes.DATA_TIMEOUT;
  const musicList = dataList?.youtubeMusicList || [];
  const pageInfo = getPageInfo(page || 1, musicList.length, LIMIT_10);
  const title = categories.find(category => category.id === categoryId)?.category;

  useEffect(() => {
    setSelectedMusicId(null);
  }, [page]);

  useEffect(() => {
    if (title) {
      setRecoilState({ title: title ? `Option.${title}` : '' });
    }
  }, [title]);

  if (!loading && !data?.youtubeMusicCategories.length && !isServerError) {
    return <Navigate to={ROUTES.YOUTUBE_CMS_MUSIC} />;
  }

  return (
    <div css={{ display: 'grid', gap: THEME.box.gaps.l }}>
      {!isMobileView && (
        <div css={styles.toolbar}>
          <div className="title-container">
            <Link to={ROUTES.YOUTUBE_CMS_MUSIC}>
              <Icomoon css={{ transform: 'rotate(90deg)' }} icon="arrow-down" />
            </Link>
            <label>{t(`Option.${title}`)}</label>
          </div>
          <div className="badge-container">
            <NotificationBadge />
          </div>
        </div>
      )}

      {loadingDataList ? (
        <ListLoading isTransparentBg />
      ) : isServerError ? (
        <div css={styles.serverErrorContainer}>
          <YoutubeCmsServerError pageTitle="Music" />
        </div>
      ) : (
        <div css={styles.contentContainer}>
          {(isMobileView ? musicList : sliceItemsOnPage(musicList, page)).map(music => {
            const isSelected = music.id === selectedMusicId;

            return (
              <MusicPlayer
                {...music}
                isSelected={isSelected}
                key={music.id}
                onClickSelectPlayback={() => setSelectedMusicId(music.id)}
              />
            );
          })}
        </div>
      )}

      {!isMobileView && !isServerError && (
        <PagerV2
          css={{ justifySelf: 'center', marginBottom: 24 }}
          currentPage={page || 1}
          first={pageInfo.firstIndex}
          last={pageInfo.lastIndex}
          totalCount={pageInfo.totalCount}
          totalPages={pageInfo.totalPages}
        />
      )}
    </div>
  );
};

const styles = {
  contentContainer: css({
    borderTop: THEME.box.borders.gray.eef3f7,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      background: THEME.colors.gray.eef3f7,
      border: THEME.box.borders.gray.eef3f7,
      borderRadius: THEME.box.borderRadius.m,
      display: 'grid',
      gap: 1,
      justifySelf: 'center',
      overflow: 'hidden',
      maxWidth: 614,
      width: '100%',
    },
  }),
  serverErrorContainer: css({
    alignItems: 'center',
    display: 'grid',
    height: 'calc(100vh - 104px)',
    justifyContent: 'center',
    justifySelf: 'center',
    maxWidth: 614,
    width: '100%',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      border: THEME.box.borders.gray.main,
      borderRadius: THEME.box.borderRadius.l,
      height: 400,
    },
  }),
  toolbar: css({
    alignItems: 'center',
    background: THEME.colors.white,
    display: 'flex',
    padding: '28px 40px',

    '&::before': {
      content: '""',
      display: 'block',
      width: 'fill-available',
    },

    '& > .title-container': {
      alignItems: 'center',
      display: 'flex',
      minWidth: 614,

      '& > a': {
        cursor: 'pointer',

        '[dir="rtl"] &': {
          transform: 'scaleX(-1)',
        },
      },

      '& > label': {
        color: THEME.font.colors.black.main,
        fontSize: THEME.font.sizes.heading,
        fontWeight: 600,
        textAlign: 'center',
        width: 'fill-available',
      },
    },

    '& .badge-container': {
      display: 'grid',
      justifyItems: 'flex-end',
      width: 'fill-available',
    },
  }),
};

export default CategoryId;
